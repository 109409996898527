import { GetAffiliate } from "../../general.service";
import { affiliates } from "../../../common/enums/affiliates";
import { analyticsConstants } from "../../../common/constants/analytics";
import { LocalStorageService } from "../../local-storage.service";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";

const gtagEnum = {
    commands: {
        event: "event",
        config: "config",
        js: "js"
    },
    types: {
        click: "click",
        conversion: "conversion",
        pageView: "page_view",
        scroll: "scroll"
    },
    indexes: {
        commands: 0,
        types: 1,
        parameters: 2
    }
}

const init = () => {
    const REACT_APP_GTM_ID = process.env.REACT_APP_GTM_ID;

    if (REACT_APP_GTM_ID) {
        const gaTrackingIds = REACT_APP_GTM_ID.split(",");
        initGtagScript(gaTrackingIds);
    }
}

const initGtagScript = (gaTrackingIds) => {
    window.dataLayer = window.dataLayer || [];
    gaTrackingIds.forEach(gaTrackingId => {
        const scriptId = `gtm_${gaTrackingId}`;
        if (!document.getElementById(scriptId)) {
            const scriptElement = document.createElement("script");
            scriptElement.setAttribute("id", scriptId);
            scriptElement.innerHTML = `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer',\"${gaTrackingId}\");
                `;
            document.getElementsByTagName("head")[0].appendChild(scriptElement);
        }
    });
}

const setGtagPageViewEventKLM = (searchDto) => {
    (function() {
        window.dataLayer = window.dataLayer || [];
        const windowDataLayerPush = window.dataLayer.push;
        window.dataLayer.push = function() {
          const items = [].slice.call(arguments, 0);
          items.forEach(function(item) {
            if (
                item[0] === gtagEnum.commands.event
                && item[1] === gtagEnum.types.pageView
                && item[2]
            ) {
                item[2].flight_origin_iata_code = LocalStorageService.getLocalStorageItem(LocalStorageEnum.FLIGHT_ORIGIN_IATA_CODE) ?? "";
                item[2].flight_destination_iata_code = LocalStorageService.getLocalStorageItem(LocalStorageEnum.FLIGHT_DESTINATION_IATA_CODE) ?? "";
            }
          });
          return windowDataLayerPush.apply(window.dataLayer, items);
        };
      })();
}

const eventTrigger = (event) => {
    if (
        event.type === analyticsConstants.eventType.pageView
        && GetAffiliate() === affiliates.klm
    ) {
        setGtagPageViewEventKLM(event);
    }
};

export const GtagService = {
    init: init,
    eventTrigger: eventTrigger
};
