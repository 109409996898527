import "./departure-dropdown.styles.scss";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { affiliates } from "../../../common/enums/affiliates";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";
import { LocalStorageService } from "../../../services/local-storage.service";
import { GetAffiliate } from "../../../services/general.service";
import { searchParamsEnum } from "../../../common/enums/searchParamsEnum";
import { DepartureLocationEnum } from "../../../common/enums/departureLocationEnum";

export const DepartureDropdownComponent = ({
  optionList,
  contentfulButtons,
  isMobile,
  departureLocationsDeepLinkParam,
  isResultsPage,
  onClose,
  performSearch,
  setSelectedDepartureInParent,
  isSearchPending,
  setIsSearchPending,
}) => {
  const containerRef = useRef(null);
  const [selectedDeparture, setSelectedDeparture] = useState(
    departureLocationsDeepLinkParam || {
      selectedByUser: true,
      departureLocationCode: DepartureLocationEnum.Amsterdam.Code,
      departureLocationName: DepartureLocationEnum.Amsterdam.DisplayName,
    }
  );
  const [tempSelectedDeparture, setTempSelectedDeparture] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortedOptionList, setSortedOptionList] = useState(optionList);
  const [isDepartureUpdating, setIsDepartureUpdating] = useState(false);

  const allAirportsOption = {
    departureLocationCode: "",
    departureLocationName: contentfulButtons?.departureLocationSelectAll || "All Airports",
  };

  useEffect(() => {
    if (departureLocationsDeepLinkParam) {
      setSelectedDeparture(departureLocationsDeepLinkParam);
      setTempSelectedDeparture(departureLocationsDeepLinkParam);
    }
  }, [departureLocationsDeepLinkParam]);

  useEffect(() => {
    if (optionList) {
      if(GetAffiliate() === affiliates.klm) {
        setSortedOptionList([ ...optionList]);
      }
      else {
        setSortedOptionList([allAirportsOption, ...optionList]);
      }
    }
  }, [optionList]);

  useEffect(() => {
    if (isSearchPending && isDepartureUpdating) {
      setIsDepartureUpdating(false);
      onClose?.();
    }
  }, [isSearchPending, isDepartureUpdating, onClose]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const saveSelection = () => {
    if (isMobile && tempSelectedDeparture) {
      setSelectedDeparture(tempSelectedDeparture);
      setSelectedDepartureInParent(tempSelectedDeparture);
      setSearchParams((params) => {
        params.set(searchParamsEnum.departureLocation, tempSelectedDeparture.departureLocationCode);
        return params;
      });
      setIsSearchPending(true);
      setIsDepartureUpdating(true);
    }
    setIsOpen(false);
  };

  const selectDepartureLocation = (option) => {
    if (isMobile) {
      setTempSelectedDeparture(option);
    } else {
      setSelectedDeparture(option);
      setSelectedDepartureInParent(option);
      setSearchParams((params) => {
        params.set(searchParamsEnum.departureLocation, option.departureLocationCode);
        return params;
      });
      setIsSearchPending(true);
      setIsDepartureUpdating(true);
      setIsOpen(false);
    }
  };

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
    if (isMobile) {
      setTempSelectedDeparture(selectedDeparture);
    }
  };

  return (
    <div className="departure-dropdown-wrapper" ref={containerRef}>
      <div className="departure-input" onClick={handleDropdownToggle}>
        <div className="input-placeholder">{selectedDeparture?.departureLocationName}</div>
        <div className={`dropdown-caret ${isOpen ? "rotate" : ""}`}></div>
      </div>
      {isOpen && sortedOptionList && (
        <div className="departure-dropdown-component">
          <div className="departure-dropdown-container">
            <div className="departure-options-container">
              {sortedOptionList.map((option) => (
                <div className="departure-row" key={option.departureLocationCode}>
                  {(isMobile ? tempSelectedDeparture : selectedDeparture)?.departureLocationCode === option.departureLocationCode && 
                  GetAffiliate() === affiliates.klm && (
                    <div className="departure-selection-highlight-bar"></div>
                  )}
                  <div
                    key={option.departureLocationCode}
                    className={`departure-row-content ${
                      (isMobile ? tempSelectedDeparture : selectedDeparture)?.departureLocationCode === option.departureLocationCode
                        ? "selected-in-dropdown-row"
                        : ""
                    }`}
                    onClick={() => selectDepartureLocation(option)}
                  >
                    {option.departureLocationName}
                  </div>
                </div>
              ))}
            </div>
            {isMobile && (
              <div className="departure-button-container">
                <button className="button-save" onClick={saveSelection}>{contentfulButtons?.save}</button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
