import "./search-bar-content-component.scss";

import React, { useEffect, useState } from "react";
import {
  formatSelectedDates,
  formatSelectedDatesForRequest,
} from "../../../services/date-formatter.service";

import { ReactComponent as AvatarIconKLM } from "./../../../assets/icons/login-alt-S.svg";
import { ReactComponent as AvatarIconTransavia } from "./../../../assets/icons/transavia/Avatar.svg";
import { Button } from "../../button/button";
import DatepickerComponent from "../../datepicker/datepicker-component";
import { DepartureDropdownComponent } from "../departure/departure-dropdown.component";
import { DepartureLocationEnum } from "../../../common/enums/departureLocationEnum";
import EditDataIconKLM from "./../../../assets/icons/klm/edit-data-S.svg";
import EditDataIconTransavia from "./../../../assets/icons/transavia/Edit.svg";
import { GetAffiliate } from "../../../services/general.service";
import { ReactComponent as HotelIconKLM } from "./../../../assets/icons/klm/hotel-S.svg";
import { ReactComponent as HotelIconTransavia } from "./../../../assets/icons/transavia/Hotel.svg";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";
import { LocalStorageService } from "../../../services/local-storage.service";
import { PagesConstant } from "../../../common/constants/pages";
import { TravellerRoomsComponent } from "../traveller-rooms/traveller-rooms.component";
import { affiliates } from "../../../common/enums/affiliates";
import { holidayTypes } from "../../../common/enums/holidayTypes";
import { pages } from "../../../common/enums/pages";
import { pagination } from "../../../common/constants/pagination";
import { searchDefaults } from "../../../common/constants/searchDefaults";
import { searchParamsEnum } from "../../../common/enums/searchParamsEnum";
import { useSearchParams } from "react-router-dom";

export const SearchBarContentComponent = ({
  isMobile,
  hotelActiveTab,
  searchInfo,
  contentfulSearchBar,
  contentfulButtons,
  contentfulRoomAllocation,
  performSearch,
  holidayHeader,
  filters,
  holidayListKey,
  defaultSearchUsed,
  setIsSearchBarOpen
}) => {
  const defaultRoom = {
    adults: 1,
    children: 0,
    babies: 0,
  };

  const [editMobile, setEditMobile] = useState(true);
  const [showTravellerRoomsComponent, setStateShowTravellerRoomsComponent] =
    useState(false);
  const [roomAllocation, setStateRoomAllocation] = useState(
    "Initial room allocation"
  );
  const [roomAllocationDisplayInfo, setStateRoomAllocationDisplayInfo] =
    useState();
  const [departureLocationsDeepLinkParam, setDepartureLocationsDeepLinkParam] =
    useState("");
  const [apiSearchInfo, setStateApiSearchInfo] = useState(null);
  const [selectedDeparture, setSelectedDeparture] = useState(null);
  const [isSearchPending, setIsSearchPending] = useState(false);
  const [selectedFormatDates, setSelectedFormatDates] = useState({});
  const showPriceCalendarFunction = apiSearchInfo?.showPriceCalendarFunction ?? false;
  const [priceCalendarSelected, setPriceCalendarSelected] = useState();

  const isResultsPage = pages.results.some((route) =>
    window.location.pathname.includes(route)
  );
  const isHolidayPage = pages.holiday.some((route) =>
    window.location.pathname.includes(route)
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelectedDepartureChange = (departure) => {
    setSelectedDeparture(departure);
  };

  useEffect(() => {
    setApiSearchInfo();
    setDepartureLocations();
    setSearchDates();
    setRoomAllocation();
    setHotelId();
  }, [searchInfo, contentfulRoomAllocation]);

  useEffect(() => {
    setSearchType();
  }, [hotelActiveTab]);

  useEffect(() => {
    setRoomAllocation();
    selectedDatesFromStorage();
  }, [searchInfo, contentfulRoomAllocation, LocalStorageService.getCulture()]);

  useEffect(() => {
    if (GetAffiliate() === affiliates.klm) {
      setSelectedDeparture({
        selectedByUser: true,
        departureLocationCode: DepartureLocationEnum.Amsterdam.Code,
        departureLocationName: DepartureLocationEnum.Amsterdam.DisplayName,
      });
    }
  }, []);

  const setApiSearchInfo = () => {
    setStateApiSearchInfo(searchInfo);
  };

  const setDepartureLocations = () => {
    if (searchInfo && searchInfo.departureLocations) {
      const selectedDepartureLocation = searchInfo.departureLocations.find(
        (e) => e.selectedByUser === true
      );
      setDepartureLocationsDeepLinkParam(selectedDepartureLocation);
    }
  };

  const setSearchDates = () => {
    if (searchInfo && searchInfo.departureLocations) {
      let output = {};

      if (isResultsPage) {
        output = {
          departureDate: searchInfo.searchDates.departureDate,
          departureDateFlexibility:
            searchInfo.searchDates.departureDateFlexibility,
          returnDate: searchInfo.searchDates.returnDate,
        };
      } else if (isHolidayPage) {
        output = {
          departureDate: holidayHeader?.searchBarInfo?.departureDate,
          departureDateFlexibility: searchInfo.departureDateFlexibility ?? 0,
          returnDate: holidayHeader?.searchBarInfo?.returnDate,
        };
      } else {
        output = {
          departureDate: formatSelectedDatesForRequest(
            searchInfo?.departureDate
          ),
          departureDateFlexibility: searchInfo.departureDateFlexibility ?? 0,
          returnDate: formatSelectedDatesForRequest(searchInfo.returnDate),
        };
      }
      LocalStorageService.setLocalStorageItem(
        LocalStorageEnum.SELECTED_DATES,
        output
      );
    }
  };

  const setSearchType = () => {
    const searchType = hotelActiveTab
      ? holidayTypes.FLIGHT_HOTEL
      : holidayTypes.FLIGHT_CAR;
    LocalStorageService.setLocalStorageItem(
      LocalStorageEnum.SEARCH_TYPE,
      searchType
    );
  };
  const setHotelId = () => {
    if (searchInfo?.hotelCode !== undefined) {
      LocalStorageService.setLocalStorageItem(
        LocalStorageEnum.HOTEL_ID,
        searchInfo?.hotelCode
      );
    }
  };

  const selectedDatesFromStorage = () => {
    const selectedDatesFromStorage = LocalStorageService.getLocalStorageItem(
      LocalStorageEnum.SELECTED_DATES
    );

    selectedFormatDates.departureDate = selectedDatesFromStorage?.departureDate
      ? formatSelectedDatesForRequest(selectedDatesFromStorage?.departureDate)
      : searchInfo?.searchDates?.departureDate;

    selectedFormatDates.displayDepartureDate =
      selectedDatesFromStorage?.displayDepartureDate
        ? formatSelectedDates(selectedDatesFromStorage?.departureDate)
        : searchInfo?.searchDates?.displayDepartureDate;

    selectedFormatDates.returnDate = selectedDatesFromStorage?.returnDate
      ? formatSelectedDatesForRequest(selectedDatesFromStorage?.returnDate)
      : searchInfo?.searchDates?.returnDate;

    selectedFormatDates.displayReturnDate = formatSelectedDates(
      selectedDatesFromStorage?.displayReturnDate
    )
      ? formatSelectedDates(selectedDatesFromStorage?.displayReturnDate)
      : searchInfo?.searchDates?.displayReturnDate;

    selectedFormatDates.departureDateFlexibility =
      selectedDatesFromStorage?.departureDateFlexibility;

    setSelectedFormatDates(selectedFormatDates);
  };

  const setRoomAllocation = () => {
    let roomAllocation;
    if (searchInfo && searchInfo.roomAllocations) {
      roomAllocation = searchInfo.roomAllocations;
    } else if (localStorage.getItem("roomAllocation")) {
      roomAllocation = JSON.parse(localStorage.getItem("roomAllocation"));
    } else {
      roomAllocation = [defaultRoom];
    }

    setStateRoomAllocation(roomAllocation);
    setRoomAllocationDisplayInfo(roomAllocation);
    LocalStorageService.setLocalStorageItem(
      LocalStorageEnum.ROOM_ALLOCATION,
      JSON.stringify(roomAllocation)
    );
    const roomAllocationCode = parseRoomAllocationToCode(roomAllocation);
  };

  const parseRoomAllocationToCode = (roomAllocation) => {
    let roomAllocationCode;
    if (roomAllocation) {
      roomAllocationCode = roomAllocation
        ?.map((e) => {
          const room = "R";
          const adults = Array(e.adults + 1).join("A");
          const children = Array(e.children + 1).join("C");
          const babies = Array(e.babies + 1).join("B");
          return room + adults + children + babies;
        })
        .join("");
    }
    return roomAllocationCode;
  };

  const setShowTravellerRoomsComponent = () => {
    if (!showTravellerRoomsComponent) {
      setStateShowTravellerRoomsComponent(true);
    }
  };

  const closeTravellerRoomsComponent = () => {
    if (showTravellerRoomsComponent) {
      setStateShowTravellerRoomsComponent(false);
    }
  };

  const updateRoomAllocation = (roomAllocation) => {
    setStateRoomAllocation(roomAllocation);
    setRoomAllocationDisplayInfo(roomAllocation);
    closeTravellerRoomsComponent();
  };

  const getNumberOfTravellers = (roomAllocation) => {
    const numberOfTravellers = roomAllocation
      .map(
        (e) => e.adults.valueOf() + e.children.valueOf() + e.babies.valueOf()
      )
      .reduce((a, b) => a + b);
    return numberOfTravellers;
  };

  const setRoomAllocationDisplayInfo = (roomAllocation) => {
    const numberOfTravellers = roomAllocation
      ? getNumberOfTravellers(roomAllocation)
      : 1;
    const numberOfRooms = roomAllocation ? roomAllocation.length : 1;
    const label = `
            ${numberOfTravellers} ${contentfulRoomAllocation?.numberOfTravellers},
            ${numberOfRooms} ${contentfulRoomAllocation?.numberOfRooms}
        `;
    const output = { numberOfTravellers, numberOfRooms, label };
    setStateRoomAllocationDisplayInfo(output);
    return output;
  };

  const handleOnEdit = () => {
    setEditMobile(!editMobile);
  };

  const setSearchRequestBody = () => {
    const searchParamsDepartureLocation = searchParams.get(
      searchParamsEnum.departureLocation
    );
    const departureLocations = searchParamsDepartureLocation;
    const destinationLocations = LocalStorageService.getLocalStorageItem(
      LocalStorageEnum.DESTINATION_LOCATIONS
    );

    const sortType = LocalStorageService.getLocalStorageItem(
      LocalStorageEnum.SELECTED_SORT_TYPE
    );

    const pageAndSorting = {
      currentPage: pagination.firstPage,
      sortType: sortType,
    };

    const searchDates = JSON.parse(
      LocalStorageService.getLocalStorageItem(LocalStorageEnum.SELECTED_DATES)
    );
    const searchType =
      LocalStorageService.getLocalStorageItem(LocalStorageEnum.SEARCH_TYPE) ??
      searchDefaults.searchType.flightHotel;
    const culture = LocalStorageService.getCulture();
    const roomAllocation = JSON.parse(
      LocalStorageService.getLocalStorageItem(LocalStorageEnum.ROOM_ALLOCATION)
    );
    const hotelId = LocalStorageService.getLocalStorageItem(
      LocalStorageEnum.HOTEL_ID
    );
    const roomAllocationCode = parseRoomAllocationToCode(roomAllocation);
    if(priceCalendarSelected) {
      const searchRequestBody = {
        culture: culture,
        destinationLocationCode: destinationLocations,
        departureLocationCode: departureLocations,
        departureDate: searchDates?.departureDate,
        returnDate: searchDates?.returnDate,
        departureDateFlexibility: searchDates?.departureDateFlexibility ?? 0,
      }
      return searchRequestBody;
    }
    if (isResultsPage) {
      const searchRequestBody = {
        departureLocationCode: departureLocations,
        destinationLocationCode: destinationLocations,
        departureDate: searchDates?.departureDate,
        returnDate: searchDates?.returnDate,
        departureDateFlexibility: searchDates?.departureDateFlexibility ?? 0,
        searchType: searchType,
        roomAllocationCode: roomAllocationCode,
        culture: culture,
        filters: filters,
        pageAndSorting: pageAndSorting,
      };
      return searchRequestBody;
    }
    if (!isResultsPage) {
      const searchRequestBody = {
        departurelocation: departureLocations,
        destinationlocation: destinationLocations,
        departuredate: searchDates?.departureDate,
        returndate: searchDates?.returnDate,
        roomallocation: roomAllocationCode,
        hotelid: hotelId,
        culture: culture,
      };
      const queryParams = new URLSearchParams(searchRequestBody);
      setSearchParams(queryParams);
    }
  };

  const getInitialDepartureNameForMobiles = () => {
    if (GetAffiliate() === affiliates.transavia) {
      return (
        departureLocationsDeepLinkParam?.departureLocationName ??
        contentfulButtons?.departureLocationSelectAll
      );
    } else {
      return DepartureLocationEnum.Amsterdam.DisplayName;
    }
  };

  useEffect(() => {
    if (isSearchPending) {
      setIsSearchPending(false);
      if (isHolidayPage) {
        window.location.href = `${
          PagesConstant.HOLIDAY
        }?${searchParams.toString()}`;
      }
    }
  }, [isSearchPending]);

  return (
    <div className="search-bar-content-component">
      {((!editMobile && isMobile) || !isMobile) && (
        <div
          className={`search-bar-container ${
            !isResultsPage ? "search-bar-padding-left" : ""
          }`}
        >
          <div className="search-bar-section">
            <div className="section-header departure-dropdown-header">
              {contentfulSearchBar?.departureLocationLabel}
            </div>
            <div className="section-content">
              <DepartureDropdownComponent
                optionList={searchInfo?.departureLocations}
                contentfulButtons={contentfulButtons}
                isMobile={isMobile}
                departureLocationsDeepLinkParam={selectedDeparture}
                performSearch={performSearch}
                isResultsPage={isResultsPage}
                onClose={() => performSearch(setSearchRequestBody())}
                setSelectedDepartureInParent={handleSelectedDepartureChange}
                isSearchPending={isSearchPending}
                setIsSearchPending={setIsSearchPending}
              />
            </div>
          </div>
          <div className="search-bar-section">
            <div className="section-header">
              {contentfulSearchBar?.travelCalendarLabel}
            </div>
            <div className="section-content">
              <div className="search-bar-section">
                <DatepickerComponent
                  contentfulButtons={contentfulButtons}
                  contentfulSearchBar={contentfulSearchBar}
                  searchDates={searchInfo?.searchDates}
                  searchInfo={searchInfo}
                  onClose={() => performSearch(setSearchRequestBody())}
                  setIsSearchPending={setIsSearchPending}
                  showPriceCalendarFunction={showPriceCalendarFunction}
                  holidayListKey={holidayListKey}
                  defaultSearchUsed={defaultSearchUsed}
                  filters={filters}
                  setPriceCalendarSelected={setPriceCalendarSelected}
                  setIsSearchBarOpen={setIsSearchBarOpen}
                />
              </div>
            </div>
          </div>
          <div className="search-bar-section">
            <div className="section-header">
              {contentfulSearchBar?.travellersAndRoomsLabel}
            </div>
            <div className="section-content">
              <div
                className="traveller-rooms-component-container"
                onClick={setShowTravellerRoomsComponent}
              >
                <div className="text">{roomAllocationDisplayInfo?.label}</div>
                <div className="dropdown-caret-wrapper">
                  <div
                    className={
                      showTravellerRoomsComponent
                        ? "dropdown-caret rotate"
                        : "dropdown-caret"
                    }
                  ></div>
                </div>
                {showTravellerRoomsComponent ? (
                  <TravellerRoomsComponent
                    contentfulButtons={contentfulButtons}
                    contentfulRoomAllocation={contentfulRoomAllocation}
                    roomAllocation={roomAllocation}
                    updateRoomAllocation={updateRoomAllocation}
                    parseRoomAllocationToCode={parseRoomAllocationToCode}
                    setShowTravellerRoomsComponent={
                      setShowTravellerRoomsComponent
                    }
                    closeTravellerRoomsComponent={() => {
                      performSearch(setSearchRequestBody());
                      closeTravellerRoomsComponent();
                      setStateShowTravellerRoomsComponent(false);
                    }}
                    closeTravellerRoomsComponentWithoutChange={() => {
                      closeTravellerRoomsComponent();
                      setStateShowTravellerRoomsComponent(false);
                    }}
                    setIsSearchPending={setIsSearchPending}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="close-button-mobile-container">
            <div
              className="close-edit-search-mobile-button"
              onClick={handleOnEdit}
            >
              {contentfulButtons?.close}
            </div>
          </div>
        </div>
      )}

      {isMobile && editMobile && (
        <div className="edit-search-mobile">
          <div className="travel-description">
            <div className="departure-location-row">
              {getInitialDepartureNameForMobiles()}
            </div>
            <div className="travel-details-row">
              <div className="departure-and-return-date">
                <div>
                  {formatSelectedDates(
                    JSON.parse(
                      LocalStorageService.getLocalStorageItem(
                        LocalStorageEnum.SELECTED_DATES
                      )
                    )?.departureDate
                  ) ?? searchInfo?.searchDates?.displayDepartureDate}
                </div>
                <div className="dash-space"> -</div>
                <div>
                  {formatSelectedDates(
                    JSON.parse(
                      LocalStorageService.getLocalStorageItem(
                        LocalStorageEnum.SELECTED_DATES
                      )
                    )?.returnDate
                  ) ?? searchInfo?.searchDates?.displayReturnDate}
                </div>
                {selectedFormatDates?.departureDateFlexibility > 0 && (
                  <div className="travel-flexibility">
                    &plusmn;{" "}
                    {selectedFormatDates?.departureDateFlexibility ??
                      searchInfo?.searchDates?.departureDateFlexibility}
                  </div>
                )}
              </div>
              <div className="rooms-and-persons">
                <span className="number-of-persons">
                  {GetAffiliate() === affiliates.klm ? (
                    <AvatarIconKLM className="svg-icon" />
                  ) : (
                    <AvatarIconTransavia className="svg-icon" />
                  )}

                  <span> {roomAllocationDisplayInfo?.numberOfTravellers} </span>
                </span>
                <span className="number-of-rooms">
                  {GetAffiliate() === affiliates.klm ? (
                    <HotelIconKLM className="svg-icon-hotel" />
                  ) : (
                    <HotelIconTransavia className="svg-icon-hotel" />
                  )}
                  <span> {roomAllocationDisplayInfo?.numberOfRooms} </span>
                </span>
              </div>
            </div>
          </div>
          <Button
            type="button"
            className="edit-search-mobile-button"
            onClickAction={handleOnEdit}
          >
            <div className="edit-search-mobile-button__img">
              <img
                src={
                  GetAffiliate() === affiliates.klm
                    ? EditDataIconKLM
                    : EditDataIconTransavia
                }
                alt="Search Edit"
              />
            </div>
            <div className="mobile-button-text">
              <span>{contentfulButtons?.customizeTrip}</span>
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};
