import { filterType } from "../../common/constants/filterType";
import { action } from "../../common/constants/action";
import { searchDefaults } from "../../common/constants/searchDefaults";

const setGenericFilterContent = (genericFilter, filter) => {
  const filterContent = { ...genericFilter };
  filter.forEach((element) => {
    const filterName = Object.keys(element)[0];
    const filterValue = element[filterName];
    filterContent[filterName] = filterValue;
  });
  return filterContent;
};

const setDepartureLocationCode = (searchInfo) => {
  const departureLocationCode = localStorage.getItem("departureLocation");
  return departureLocationCode;
};

const setFilterDestinationLocations = (searchInfo, filter) => {
  if (!Array.isArray(filter)) {
    filter = [filter];
  }

  let destinationLocationCode = searchInfo.destinationLocationCode.split(",");
  if (!filter[0]?.action) {
    destinationLocationCode = filter;
  } else if (filter[0].action === action.add) {
    destinationLocationCode.length = 0;
    filter.forEach((element) => {
      destinationLocationCode.push(element.value);
    });
  } else if (filter[0].action === action.delete) {
    filter.forEach((element) => {
      destinationLocationCode = destinationLocationCode.filter(
        (code) => code !== element.value
      );
    });
  } else {
    console.error(action.unknown);
  }
  return destinationLocationCode.join(",");
};

const setRoomAllocationCode = (searchInfo) => {
  const roomAllocationCode = searchInfo.roomAllocations
    .map((e) => {
      const room = "R";
      const adults = Array(e.adults + 1).join("A");
      const children = Array(e.children + 1).join("C");
      const babies = Array(e.babies + 1).join("B");
      return room + adults + children + babies;
    })
    .join("");
  return roomAllocationCode;
};

const setSearchRequestBody = (holidayFullData, requestedFilters, filter) => {
  const searchInfo = { ...holidayFullData.data.searchInfo };
  const departureLocationCode = filter
    ? setDepartureLocationCode(searchInfo)
    : "";
  const destinationLocationCode = filter
    ? setFilterDestinationLocations(searchInfo, filter)
    : "";
  const roomAllocationCode = filter
    ? setRoomAllocationCode(searchInfo)
    : searchDefaults.roomAllocation;

  const searchRequestBody = {};
  Object.entries(searchInfo.searchDates).forEach(([key, value]) => {
    searchRequestBody[key] = value;
  });
  searchRequestBody.departureLocationCode = departureLocationCode;
  searchRequestBody.destinationLocationCode = destinationLocationCode;
  searchRequestBody.roomAllocationCode = roomAllocationCode;
  searchRequestBody.searchType = localStorage.getItem("searchType");
  searchRequestBody.culture = localStorage.getItem("HOLIDAYS_CULTURE");
  searchRequestBody.filters = requestedFilters ? requestedFilters : null;

  return searchRequestBody;
};

const setAddFilterRequestBody = (
  holidayListKey,
  destinationLocationCode,
  requestedFilters
) => {
  const filterRequestBody = {
    holidayListKey: holidayListKey,
    destinationLocationCode: destinationLocationCode,
    filters: { ...requestedFilters },
  };
  return filterRequestBody;
};

const setDeleteFilterRequestBody = (
  holidayListKey,
  requestedFilters,
  filter
) => {
  const filterRequestBody = {
    holidayListKey: holidayListKey,
    filters: { ...requestedFilters },
  };
  if (filter.value === filterType.amenity) {
    filterRequestBody.filters.selectedAmenities =
      filterRequestBody.filters.selectedAmenities.filter(
        (e) => e !== filter.text
      );
  } else if (filter.value === filterType.stars) {
    filterRequestBody.filters.stars = filterRequestBody.filters.stars.filter(
      (e) => e !== Number(filter.text)
    );
    if (!filterRequestBody.filters.stars.length) {
      filterRequestBody.filters.stars = null;
    }
  } else if (filter.value === filterType.theme) {
    filterRequestBody.filters.selectedThemes =
      filterRequestBody.filters.selectedThemes.filter((e) => e !== filter.text);
  } else if (filter.value === filterType.price) {
    filterRequestBody.filters.minPrice = null;
    filterRequestBody.filters.maxPrice = null;
  } else if (filter.value === filterType.distanceToCenter) {
    filterRequestBody.filters.maxDistanceToCenter = null;
  } else {
    filterRequestBody.filters[filter.value] = null;
  }

  return filterRequestBody;
};

export const methods = {
  setGenericFilterContent: setGenericFilterContent,
  setFilterDestinationLocations: setFilterDestinationLocations,
  setSearchRequestBody: setSearchRequestBody,
  setAddFilterRequestBody: setAddFilterRequestBody,
  setDeleteFilterRequestBody: setDeleteFilterRequestBody,
};
