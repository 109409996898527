export const LocalStorageEnum = {
  ROOM_ALLOCATION: "roomAllocation",
  HOTEL_ID: "hotelId",
  HOTEL_DATA: "hotelData",
  HOLIDAYS_CULTURE: "HOLIDAYS_CULTURE",
  DEPARTURE_LOCATION: "departureLocation",
  DESTINATION_LOCATIONS: "destinationLocations",
  DESTINATION_AIRPORT_CODE: "destinationAirportCode",
  IS_ALL_DEPARTURES: "isAllDepartures",
  SELECTED_DATES: "selectedDates",
  SEARCH_TYPE: "searchType",
  LAST_HOLIDAY_KEY: "lastHolidayKey",
  PASSENGER_DETAILS: "passengerDetails",
  CONTENTFUL_ENTRIES: "contentfulEntries",
  HOTEL_AMENITIES: "hotelAmenities",
  GENERAL_DESCRIPTION: "generalDescription",
  CHECK_IN_INSTRUCTION: "hotelDescription_checkInInstruction",
  AMENITIES_DESCRIPTION: "hotelDescription_amenitiesDescription",
  KNOW_BEFORE_YOU_GO_DESCRIPTION: "hotelDescription_knowBeforeYouGoDescription",
  MANDATORY_FEE_DESCRIPTION: "hotelDescription_mandatoryFeeDescription",
  SELECTED_SORT_TYPE: "selectedSortType",
  CABIN_SELECTION_STATE: "cabinSelectionState",
  CELL_SELECTED: "selectedCell",
  ISSUER_ID: "selectedIssuerId",
  FLIGHT_ORIGIN_IATA_CODE: "flight_origin_iata_code",
  FLIGHT_DESTINATION_IATA_CODE: "flight_destination_iata_code",
  IS_CHECKOUT_PAGE: "isCheckoutPage"
};
