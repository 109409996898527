export const filterType = {
  amenity: "amenity",
  cities: "cities",
  countries: "countries",
  destination: "destination",
  generic: "generic",
  theme: "theme",
  price: "price",
  distanceToCenter: "distanceToCenter",
  toCentre: "To centre",
  starsText: "stars",
  stars: "stars",
  minTripAdvisorRating: "minTripAdvisorRating",
  good: "3",
  veryGood: "4",
  excellent: "5",
  goodText: "Good",
  veryGoodText: "Very good",
  excellentText: "Excellent",
  none: "",
  maximumNumberOfCountries: 3,
  amenitiesFilterNumberOfOptionsToShow: 5,
  allInclusive: "AllInclusive",
  selectedAllInclusive: "selectedAllInclusive",
};
