import "./../selected-filters/selected-filters.component.scss";
import "./destination-filter.component.scss";

import { useCallback, useEffect, useRef, useState } from "react";

import { useSearchParams } from "react-router-dom";
import { action } from "../../../common/constants/action";
import { filterType } from "../../../common/constants/filterType";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";
import { searchParamsEnum } from "../../../common/enums/searchParamsEnum";
import config from "../../../environments/config.json";
import { LocalStorageService } from "../../../services/local-storage.service";
import { Accordion } from "../../accordion/accordion";
import { FilterChipComponent } from "../selected-filters/filter-chip/filter-chip-component";

export const DestinationFilterComponent = ({
  contentfulButtons,
  contentfulFilters,
  holidayFullData,
  data,
  setDestinationFilter,
  defaultSearchUsed,
  setCountriesLocations,
  setCitiesLocations,
  setDestinationFilters
}) => {
  const [apiCountryFilterOptions, setStateApiCountryFilterOptions] =
    useState(null);
  const [apiCityFilterOptions, setStateApiCityFilterOptions] = useState(null);
  const [countryFilterOptions, setStateCountryFilterOptions] = useState(null);
  const [cityFilterOptions, setStateCityFilterOptions] = useState(null);
  const [
    selectedMaximumNumberOfCountries,
    setStateSelectedMaximumNumberOfCountries,
  ] = useState(false);
  const [saveButtonDisabled, setStateSaveButtonDisabled] = useState(false);
  const [
    showAvailableCountryFilterOptions,
    setStateShowAvailableCountryFilterOptions,
  ] = useState(false);
  const [
    showAvailableCityFilterOptions,
    setStateShowAvailableCityFilterOptions,
  ] = useState(false);

  const [countriesSelectorText, setStateCountriesSelectorText] = useState(null);
  const [citiesSelectorText, setStateCitiesSelectorText] = useState(null);
  const [holidayListKey, setStateHolidaysListKey] = useState("");
  const [countriesFilterChips, setCountriesFilterChips] = useState(null);
  const [citiesFilterChips, setCitiesFilterChips] = useState(null);
  const [requestedFilters, setStateRequestedFilters] = useState(null);
  const destinationFilterComponentSectionCountryFilterOptions = useRef(null);
  const destinationFilterComponentSectionCityFilterOptions = useRef(null);
  const backupApiCountryFilterOptions = useRef(null);
  const backupApiCityFilterOptions = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [citySearchText, setCitySearchText] = useState("");
  const filtersButtonContainer = document.getElementById(
    "filters-button-container"
  );
  const destinationFilterEnum = {
    numberOfCountryNamesToDisplay: 2,
    numberOfCityNamesToDisplay: 2,
    numberOfMaxCountries: 3
  };

  let [searchParams, setSearchParams] = useSearchParams();

  const getFilteredCountries = useCallback(() => {
    if (!searchText || typeof searchText !== 'string') {
      return countryFilterOptions?.map((country, index) => ({
        ...country,
        originalIndex: index
      }));
    }
  
    const trimmedSearch = searchText.trim();
    if (trimmedSearch === '') {
      return countryFilterOptions?.map((country, index) => ({
        ...country,
        originalIndex: index
      }));
    }
    
    return countryFilterOptions
      ?.filter((country) => {
        return country.displayName?.toLowerCase().includes(trimmedSearch.toLowerCase());
      })
      .map(country => {
        const originalIndex = countryFilterOptions.findIndex(
          c => c.destinationCode === country.destinationCode
        );
        return {
          ...country,
          originalIndex
        };
      });
  }, [countryFilterOptions, searchText]);
  
  const getFilteredCities = useCallback(() => {
    if (!citySearchText || typeof citySearchText !== 'string') {
      return cityFilterOptions?.map((city, index) => ({
        ...city,
        originalIndex: index
      }));
    }
  
    const trimmedSearch = citySearchText.trim();
    if (trimmedSearch === '') {
      return cityFilterOptions?.map((city, index) => ({
        ...city,
        originalIndex: index
      }));
    }
    
    return cityFilterOptions
      ?.filter((city) => {
        return city.displayName?.toLowerCase().includes(trimmedSearch.toLowerCase());
      })
      .map(city => {
        const originalIndex = cityFilterOptions.findIndex(
          c => c.destinationCode === city.destinationCode
        );
        return {
          ...city,
          originalIndex
        };
      });
  }, [cityFilterOptions, citySearchText]);

  useEffect(() => {
    setStateApiCountryFilterOptions(holidayFullData?.data?.filterInfo?.filterOptions?.countryFilterOptions);
    setStateApiCityFilterOptions(holidayFullData?.data?.filterInfo?.filterOptions?.cityFilterOptions);

    if (!data?.countryFilterOptions) {
        return;
    }

    const destinationLocationParam = searchParams.get(searchParamsEnum.destinationLocation);

    let updatedCountryFilterOptions;

    if (destinationLocationParam) {
        let destinationCountriesFromURL = destinationLocationParam.split(",");

        destinationCountriesFromURL = destinationCountriesFromURL
            .map((entry) => entry.split("_")[0]) 
            .filter((code) => data.countryFilterOptions.some((c) => c.destinationCode === code));

        const uniqueCountries = [...new Set(destinationCountriesFromURL)].slice(0, destinationFilterEnum.numberOfMaxCountries);

        updatedCountryFilterOptions = data.countryFilterOptions.map((country) => ({
            ...country,
            selected: uniqueCountries.includes(country.destinationCode)
        }));

    } else {
        updatedCountryFilterOptions = apiCountryFilterOptions
            ? [...apiCountryFilterOptions]
            : JSON.parse(JSON.stringify(data.countryFilterOptions));
    }

    setCountriesSelectorText(updatedCountryFilterOptions);
    setStateCountryFilterOptions(updatedCountryFilterOptions);
    backupApiCountryFilterOptions.current = JSON.parse(JSON.stringify(updatedCountryFilterOptions));

    updateFilterOptions(apiCountryFilterOptions, apiCityFilterOptions);
  }, [data, apiCountryFilterOptions, apiCityFilterOptions, searchParams]);

  useEffect(() => {
    if (holidayFullData) {
      setStateHolidaysListKey(holidayFullData?.data?.holidayListKey);
      setStateRequestedFilters(holidayFullData?.data?.filterInfo?.requestedFilters);
      const destinationFilterValues = holidayFullData?.data?.filterInfo?.destinationFilterChips
          .map(destination => destination.destinationCode)
          .join(",");

      if (destinationFilterValues?.length > 0) {
        setSearchParams((params) => {
          params.set(searchParamsEnum.destinationLocation, destinationFilterValues);
          return params;
        });
        LocalStorageService.setLocalStorageItem(LocalStorageEnum.DESTINATION_LOCATIONS, destinationFilterValues);
      } else {
        setSearchParams((params) => {
          params.delete(searchParamsEnum.destinationLocation);
        });
        setSearchParams(searchParams.toString());
        LocalStorageService.setLocalStorageItem(LocalStorageEnum.DESTINATION_LOCATIONS, "");
      }
    }
  }, [holidayFullData]);

  const updateFilterOptions = (countryOptions, cityOptions) => {
    if (countryOptions) {
      const selectedCountryCount = countryOptions.filter((option) => option?.selected).length;

      if (selectedCountryCount < destinationFilterEnum.numberOfMaxCountries) {
        const clonedCountryCityOptions = structuredClone(countryOptions);
        setCountriesSelectorText(clonedCountryCityOptions);
        setStateCountryFilterOptions(clonedCountryCityOptions);
      }
    }

    if (cityOptions) {
      const clonedCityOptions = structuredClone(cityOptions);
      setCitiesSelectorText(clonedCityOptions);
      setStateCityFilterOptions(clonedCityOptions);
      backupApiCityFilterOptions.current = clonedCityOptions;
    }
  };

  const setCountriesSelectorText = (collection) => {
    const displayNames = collection
      .filter((element) => element.selected)
      .map((element) => element.displayName);
      const selectedCountries = filterSelectedDestinations(collection);
      setCountriesFilterChips(selectedCountries);

    if (
      displayNames.length <= destinationFilterEnum.numberOfCountryNamesToDisplay
    ) {
      const text = displayNames.join(", ");
      setStateCountriesSelectorText(text);
    } else {
      const text =
        displayNames
          .slice(0, destinationFilterEnum.numberOfCountryNamesToDisplay)
          .join(", ") +
        `, +${
          displayNames.length -
          destinationFilterEnum.numberOfCountryNamesToDisplay
        }`;
      setStateCountriesSelectorText(text);
    }
  };

  const filterSelectedDestinations = (countryFilterOptions) => {
    return countryFilterOptions?.filter((element) => element.selected) || [];
  }

  const setCitiesSelectorText = (collection) => {
    const displayNames = collection
      .filter((element) => element.selected)
      .map((element) => element.displayName);

    const selectedCities = filterSelectedDestinations(collection);
    setCitiesFilterChips(selectedCities);

    if (
      displayNames.length <= destinationFilterEnum.numberOfCityNamesToDisplay
    ) {
      const text = displayNames.join(", ");
      setStateCitiesSelectorText(text);
    } else {
      const text =
        displayNames
          .slice(0, destinationFilterEnum.numberOfCityNamesToDisplay)
          .join(", ") +
        `, +${
          displayNames.length - destinationFilterEnum.numberOfCityNamesToDisplay
        }`;
      setStateCitiesSelectorText(text);
    }
  };

  const addEventListenerCountries = () => {
    document.addEventListener("click", handleClickEventCountries);
  };

  const removeEventListenerCountries = () => {
    document.removeEventListener("click", handleClickEventCountries);
  };

  const handleClickOutside = (type, filterOptions, apiFilterOptions, setShowState, removeListener) => {
    if (filterOptions) {
      const isSelectionChanged = checkSelectionChanged(apiFilterOptions, filterOptions);
      const shouldSave = type === filterType.countries 
        ? (!saveButtonDisabled && isSelectionChanged)
        : isSelectionChanged;
      if (shouldSave) {
        handleOnClickSave(type);
      } else {
        setShowState(false);
        removeListener();
      }
    } else {
      setShowState(false);
      removeListener();
    }
  };

  const handleClickEventCountries = (event) => {
    if (event.target.classList.contains('search-input')) {
      return; 
    }
  
    if (
      destinationFilterComponentSectionCountryFilterOptions.current &&
      !destinationFilterComponentSectionCountryFilterOptions.current.contains(
        event.target
      )
      && event.target.className !== 'button-filter-action'
    ) {
      handleClickOutsideElement(filterType.countries, countryFilterOptions);
    } 
  };

  const addEventListenerCities = () => {
    document.addEventListener("click", handleClickEventCities);
  };

  const removeEventListenerCities = () => {
    document.removeEventListener("click", handleClickEventCities);
  };

  const handleClickEventCities = (event) => {
    if (event.target.classList.contains('search-input')) {
      return;
    }
  
    if (
      destinationFilterComponentSectionCityFilterOptions.current &&
      !destinationFilterComponentSectionCityFilterOptions.current.contains(
        event.target
      )
      && event.target.className !== 'button-filter-action'
    ) {
      handleClickOutside(
        filterType.cities, 
        cityFilterOptions, 
        apiCityFilterOptions, 
        setStateShowAvailableCityFilterOptions, 
        removeEventListenerCities
      );
    }
  };

  const handleClickOutsideElement = (type) => {
    handleOnClickCancel(type);
  };

  const toggleFiltersButtonContainerVisibility = (setVisible) => {
    if (setVisible) {
      filtersButtonContainer?.classList.remove("hidden");
      filtersButtonContainer?.classList.add("visible");
    } else {
      filtersButtonContainer?.classList.remove("visible");
      filtersButtonContainer?.classList.add("hidden");
    }
  };

  const toggleShowAvailableFilterOptions = (type, preventClose = null) => {
    toggleFiltersButtonContainerVisibility();
  
    if (type === filterType.countries) {
      if (showAvailableCityFilterOptions) {
        setStateShowAvailableCityFilterOptions(false);
        setStateCityFilterOptions(backupApiCityFilterOptions.current);
        setCitiesSelectorText(backupApiCityFilterOptions.current);
        setCitySearchText("");
        removeEventListenerCities();
      }
  
      const newCountryFilterState = preventClose !== null ? preventClose : !showAvailableCountryFilterOptions;
      setStateShowAvailableCountryFilterOptions(newCountryFilterState);
      
      if (newCountryFilterState) { 
        backupApiCountryFilterOptions.current = structuredClone(countryFilterOptions);
        setSelectedMaximumNumberOfCountries();
        setSaveButtonDisabled();
        addEventListenerCountries();
      } else {
        removeEventListenerCountries();
      }
      
      setSearchText("");
    } 
    else if (type === filterType.cities) {
      if (showAvailableCountryFilterOptions) {
        setStateShowAvailableCountryFilterOptions(false);
        setStateCountryFilterOptions(backupApiCountryFilterOptions.current);
        setCountriesSelectorText(backupApiCountryFilterOptions.current);
        setSearchText("");
        removeEventListenerCountries();
      }
  
      const newCityFilterState = preventClose !== null ? preventClose : !showAvailableCityFilterOptions;
      setStateShowAvailableCityFilterOptions(newCityFilterState);
      
      if (newCityFilterState) { 
        backupApiCityFilterOptions.current = structuredClone(cityFilterOptions);
        addEventListenerCities();
      } else {
        removeEventListenerCities();
      }
      setCitySearchText("");
    }
  };

  const setSelectedMaximumNumberOfCountries = () => {
    setStateSelectedMaximumNumberOfCountries(
      countryFilterOptions &&
        countryFilterOptions.filter((element) => element.selected).length >=
        filterType.maximumNumberOfCountries
        ? true
        : false
    );
  };

  const setSaveButtonDisabled = () => {
    setStateSaveButtonDisabled(
      countryFilterOptions &&
        (countryFilterOptions.filter((element) => element.selected).length ===
          0 ||
          countryFilterOptions.filter((element) => element.selected).length >
            filterType.maximumNumberOfCountries)
        ? true
        : false
    );
  };

  const handleOnClickFilterOptions = (type, index) => {
    if (type === filterType.countries) {
      const updatedCountryFilterOptions = [...countryFilterOptions];
      updatedCountryFilterOptions[index].selected =
        !updatedCountryFilterOptions[index].selected;
      setStateCountryFilterOptions(updatedCountryFilterOptions);
      setSelectedMaximumNumberOfCountries();
      if(setCountriesLocations) {
        setCountriesLocations(updatedCountryFilterOptions)
      }
      setSaveButtonDisabled();
      setCountriesSelectorText(updatedCountryFilterOptions);
    } else if (type === filterType.cities) {
      const updatedCityFilterOptions = [...cityFilterOptions];
      updatedCityFilterOptions[index].selected =
        !updatedCityFilterOptions[index].selected;
      setStateCityFilterOptions(updatedCityFilterOptions);
      if(setCitiesLocations) {
        setCitiesLocations(updatedCityFilterOptions);
      }
      setCitiesSelectorText(updatedCityFilterOptions);
    }
  };

  const handleOnClickCancel = (type) => {
    toggleFiltersButtonContainerVisibility(true);
    if (type === filterType.countries) {
      setStateShowAvailableCountryFilterOptions(false);
      setStateCountryFilterOptions(countryFilterOptions);
      setCountriesSelectorText(countryFilterOptions);
      handleOnClickSave(filterType.countries, countryFilterOptions)
      removeEventListenerCountries();
    } else if (type === filterType.cities) {
      setStateShowAvailableCityFilterOptions(false);
      setStateCityFilterOptions(backupApiCityFilterOptions.current);
      setCitiesSelectorText(backupApiCityFilterOptions.current);
      handleOnClickSave(filterType.cities, cityFilterOptions)
      removeEventListenerCities();
    }
  };


  const checkSelectionChanged = (holidayData, filterOptions)  => {
    return holidayData?.some((item, index) => item.selected !== filterOptions[index].selected);
  }

  const handleOnClickSave = (type) => {
    toggleFiltersButtonContainerVisibility(true);
    const isCountriesSelectionChanged = checkSelectionChanged(apiCountryFilterOptions, countryFilterOptions);
    const isCitiesSelectionChanged = checkSelectionChanged(apiCityFilterOptions, cityFilterOptions);

    if (!saveButtonDisabled && (isCountriesSelectionChanged || isCitiesSelectionChanged)) {
      const output = [...countryFilterOptions, ...cityFilterOptions]
        .filter((element) => element.selected)
        .map((element) => {
          return {
            type: filterType.destination,
            value: element.destinationCode,
            text: element.displayName,
            action: action.add,
          };
        });
      const destinationParams = output
        .map((selectedFilter) => selectedFilter.value)
        .join(",");

      setSearchParams((params) => {
        params.set(searchParamsEnum.destinationLocation, destinationParams);
        return params;
      });
      LocalStorageService.setLocalStorageItem(
        LocalStorageEnum.DESTINATION_LOCATIONS,
        destinationParams
      );

      if(setDestinationFilters) {
        setDestinationFilters(output)
      }

      setDestinationFilter(output);
      setStateShowAvailableCountryFilterOptions(false);
      setStateShowAvailableCityFilterOptions(false);
      if (type === filterType.countries) {
        removeEventListenerCountries();
      } else if (type === filterType.cities) {
        removeEventListenerCities();
      }
    }
  };
  const handleOnFilterChipRemove = (type, filter) => {
    if (type === filterType.countries) {
      const updatedCountryFilterOptions = [...countryFilterOptions];
      const selectedCountryIndex = updatedCountryFilterOptions.findIndex(
        (element) => element.destinationCode === filter.destinationCode);
      updatedCountryFilterOptions[selectedCountryIndex].selected = false;
      setStateCountryFilterOptions(updatedCountryFilterOptions);
      setSelectedMaximumNumberOfCountries();
      if(setCountriesLocations) {
        setCountriesLocations(updatedCountryFilterOptions)
      }
      setSaveButtonDisabled();
      setCountriesSelectorText(updatedCountryFilterOptions);
    } else if (type === filterType.cities) {
      const updatedCityFilterOptions = [...cityFilterOptions];
      const selectedCityIndex = updatedCityFilterOptions.findIndex(
        (element) => element.destinationCode === filter.destinationCode);
      updatedCityFilterOptions[selectedCityIndex].selected = false;

      setStateCityFilterOptions(updatedCityFilterOptions);
      if(setCitiesLocations) {
        setCitiesLocations(updatedCityFilterOptions);
      }
      setCitiesSelectorText(updatedCityFilterOptions);
    }
  };

  const sectionCountryFilterOptions = (
    <div
      id="destination-filter-component-section-country-filter-options"
      className="filter-options"
      ref={destinationFilterComponentSectionCountryFilterOptions}
    >
      <div className="header">
        {contentfulFilters
          ? contentfulFilters[0]?.fields?.destinationFilterCountriesHeader
          : "Countries"}
      </div>
  
      <div
        className={
          showAvailableCountryFilterOptions ? "selector active" : "selector"
        }
        onClick={(e) => {
          if (e.target.classList.contains('search-input')) {
            e.stopPropagation();
            return; 
          }
          toggleShowAvailableFilterOptions(filterType.countries);
        }}
      >
        <div className="icon-location">&nbsp;</div>
        <div 
          className="text"
          onClick={(e) => {
            e.stopPropagation();
            toggleShowAvailableFilterOptions(filterType.countries);
          }}
        >
          {showAvailableCountryFilterOptions ? (
            <input
              type="text"
              className="search-input"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={contentfulFilters && contentfulFilters[0]?.fields?.destinationPlaceholderSearch}
              autoFocus
              onClick={(e) => e.stopPropagation()} 
            />
          ) : countriesSelectorText ? (
            <div className="active">{countriesSelectorText}</div>
          ) : (
            <div className="inactive">
              {contentfulFilters && contentfulFilters[0]?.fields?.destinationFilterCountriesSelectorPrompt }
            </div>
          )}
        </div>
        <div 
          className="icon-arrow"
          onClick={(e) => {
            e.stopPropagation(); 
            toggleShowAvailableFilterOptions(filterType.countries);
          }}
        >
          <div
            className={
              showAvailableCountryFilterOptions ? "icon-drop-up" : "icon-drop-down"
            }
          ></div>
        </div>
      </div>
  
      <div className="options-container">
        {showAvailableCountryFilterOptions ? (
          <>
            <div className="options-content-container">
            {
              <>
              <div className="options-header">
                {contentfulFilters && contentfulFilters[0]?.fields?.destinationSearchTitle}
              </div>
              <div className="selected-filters-component">
                <div className="ml-2 selected-filters-container">
                  {countriesFilterChips?.map((filter, index) => (
                    <FilterChipComponent
                      key={index}
                      data={filter}
                      contentfulFilters={contentfulFilters}
                      isFilterChipAction={true}
                      performFilterChipAction={() => handleOnFilterChipRemove(filterType.countries, filter)}
                    />
                  ))}
                </div>
              </div>
              </>
            }
              <div className="options-header">
                {contentfulFilters && contentfulFilters[0]?.fields?.destinationFilterCountriesOptionsHeader }
              </div>
  
              {selectedMaximumNumberOfCountries ? (
                <div className="options-error">
                  <div className="icon"></div>
                  <div className="text">
                    {contentfulFilters
                      ? contentfulFilters[0]?.fields
                          ?.destinationFilterCountriesOptionsErrorText
                      : "You have selected the maximum number of 3 countries."}
                  </div>
                </div>
              ) : null}
  
          <div className="options-content">
            {getFilteredCountries()?.map((element) => {
              return (
                <div
                  className={`option 
                            ${(selectedMaximumNumberOfCountries && countryFilterOptions[element.originalIndex]?.selected) || !selectedMaximumNumberOfCountries ? '' : 'disable-option-cursor'} `}
                  key={element.destinationCode}
                  onClick={() => {
                    if ((selectedMaximumNumberOfCountries && countryFilterOptions[element.originalIndex]?.selected) || !selectedMaximumNumberOfCountries) {
                      handleOnClickFilterOptions(filterType.countries, element.originalIndex);
                    }
                  }}
                >
                  <div
                    className={`checkbox-container 
                      ${countryFilterOptions && countryFilterOptions[element.originalIndex]?.selected ? "active": "inactive"} 
                      ${selectedMaximumNumberOfCountries && !countryFilterOptions[element.originalIndex]?.selected? 'disable-checkbox': ''}`
                    }
                  >
                    <div className={`checkbox 
                                    ${(selectedMaximumNumberOfCountries && !countryFilterOptions[element.originalIndex]?.selected)  ? 'disable-checkbox' : ''}`}
                    >
                    </div>
                  </div>

                  <div className="flag-container">
                    <img
                      className={`flag-small 
                                ${(selectedMaximumNumberOfCountries && !countryFilterOptions[element.originalIndex]?.selected)  ? 'disable-flag' : ''}`}
                      alt={element.countryCode}
                      src={`${config.COUNTRY_FLAGS}/${element.countryCode.toLowerCase()}.png`}
                    />
                  </div>
              
                  <div className={`text-container
                                ${(selectedMaximumNumberOfCountries && !countryFilterOptions[element.originalIndex]?.selected)  ? 'disable-text' : ''}`}
                  >
                    {element.displayName}
                  </div>
                </div>
              );
            })}
          </div>
              <div className="options-button-container">
                <div
                  onClick={() => {
                    handleOnClickCancel(filterType.countries);
                  }}
                  className="cancel-button"
                >
                  {contentfulButtons
                    ? contentfulButtons[0]?.fields?.cancel
                    : "Cancel"}
                </div>
                <div
                  className={saveButtonDisabled ? "disabled" : "enabled"}
                  onClick={() => {
                    handleOnClickSave(filterType.countries);
                  }}
                >
                  {contentfulButtons
                    ? contentfulButtons[0]?.fields?.save
                    : "Save"}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );

  const sectionCityFilterOptions = (
    <div
      id="destination-filter-component-section-city-filter-options"
      className="filter-options"
      ref={destinationFilterComponentSectionCityFilterOptions}
    >
      <div className="header">
        {contentfulFilters
          ? contentfulFilters[0]?.fields?.destinationFilterCitiesHeader
          : "Cities"}
      </div>
  
      <div
        className={
          showAvailableCityFilterOptions ? "selector active" : "selector"
        }
        onClick={(e) => {
          if (e.target.classList.contains('search-input')) {
            e.stopPropagation();
            return;
          }
          toggleShowAvailableFilterOptions(filterType.cities);
        }}
      >
        <div className="icon-location">&nbsp;</div>
        <div 
          className="text"
          onClick={(e) => {
            e.stopPropagation();
            toggleShowAvailableFilterOptions(filterType.cities);
          }}
        >
          {showAvailableCityFilterOptions ? (
            <input
              type="text"
              className="search-input"
              value={citySearchText}
              onChange={(e) => setCitySearchText(e.target.value)}
              placeholder={contentfulFilters && contentfulFilters[0]?.fields?.destinationPlaceholderSearch}
              autoFocus
              onClick={(e) => e.stopPropagation()} 
            />
          ) : citiesSelectorText ? (
            <div className="active">{citiesSelectorText}</div>
          ) : (
            <div className="inactive">
              {contentfulFilters
                ? contentfulFilters[0]?.fields
                    ?.destinationFilterCitiesSelectorPrompt
                : "Choose a city..."}
            </div>
          )}
        </div>
        <div 
          className="icon-arrow"
          onClick={(e) => {
            e.stopPropagation();
            toggleShowAvailableFilterOptions(filterType.cities);
          }}
        >
          <div
            className={
              showAvailableCityFilterOptions ? "icon-drop-up" : "icon-drop-down"
            }
          ></div>
        </div>
      </div>
  
      <div className="options-container">
        {showAvailableCityFilterOptions ? (
          <>
            <div className="options-content-container">
            {!defaultSearchUsed &&
            <>
              <div className="options-header">
                { contentfulFilters && contentfulFilters[0]?.fields?.destinationSearchTitleCities }
              </div>
              <div className="selected-filters-component">
                <div className="ml-2 selected-filters-container">
                  {citiesFilterChips?.map((filter, index) => (
                    <FilterChipComponent
                      key={index}
                      data={filter}
                      contentfulFilters={contentfulFilters}
                      isFilterChipAction={true}
                      performFilterChipAction={() => handleOnFilterChipRemove(filterType.cities, filter)}
                    />
                  ))}
                </div>
              </div>
            </>
             }
              <div className="options-header">
                {contentfulFilters && contentfulFilters[0]?.fields?.destinationFilterCitiesOptionsHeader }
              </div>
  
              <div className="options-content">
                {getFilteredCities()?.map((element, index) => {
                  const originalIndex = cityFilterOptions.findIndex(
                    city => city.destinationCode === element.destinationCode
                  );
                  return (
                    <div
                      key={element.destinationCode}
                      className="option"
                      onClick={() => {
                        handleOnClickFilterOptions(filterType.cities, originalIndex);
                      }}
                    >
                      <div
                        className={
                          cityFilterOptions &&
                          cityFilterOptions[originalIndex]?.selected
                            ? "checkbox-container active"
                            : "checkbox-container inactive"
                        }
                      >
                        <div className="checkbox"></div>
                      </div>
  
                      <div className="flag-container">
                        <img
                          className="flag-small"
                          alt={element.countryCode}
                          src={`${config.COUNTRY_FLAGS}/${element.countryCode.toLowerCase()}.png`}
                        />
                      </div>
  
                      <div className="text-container">
                        {element.displayName}
                      </div>
                    </div>
                  );
                })}
              </div>
  
              <div className="options-button-container">
                <div
                  className="enabled"
                  onClick={() => {
                    handleOnClickSave(filterType.cities);
                  }}
                >
                  {contentfulButtons
                    ? contentfulButtons[0]?.fields?.save
                    : "Save"}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
  
  return (
    <div
      id="destination-filter-component-container"
      className="destination-filter-component-container"
    >
      <Accordion
        startingExpandedState="false"
        defaultSearchUsed={defaultSearchUsed}
        labelText={
          contentfulFilters
            ? contentfulFilters[0]?.fields?.destinationFilterHeader
            : "Destination"
        }
      >
        <div className="destination-filter-component-content">
          {sectionCountryFilterOptions}
          {sectionCityFilterOptions}
        </div>
      </Accordion>
    </div>
  );
};
