import React, { useEffect, useRef, useState } from "react";
import {
  GetCosts,
  InitiatePayment,
  MakePayment,
  SelectPaymentMethod,
} from "../../../services/adyen/adyen.service";
import AdyenCheckout from "@adyen/adyen-web";
import IdealIcon from "./../../../assets/images/payments/ideal_logo_border_klm.svg";
import AmericanExpressIcon from "./../../../assets/images/payments/amex_logo_border_klm.svg";
import MasterCardIcon from "./../../../assets/images/payments/mc_logo_border_klm.svg";
import VisaCardIcon from "./../../../assets/images/payments/visa_logo_border_klm.svg";

import IdealIconTransavia from "./../../../assets/images/payments/ideal_logo_border_transavia.svg";
import AmericanExpressIconTransavia from "./../../../assets/images/payments/amex_logo_border_transavia.svg";
import MasterCardIconTransavia from "./../../../assets/images/payments/mc_logo_border_transavia.svg";
import VisaCardIconTransavia from "./../../../assets/images/payments/visa_logo_border_transavia.svg";

import { GetAffiliate } from "../../../services/general.service";
import { affiliates } from "../../../common/enums/affiliates";
import { ReactComponent as AdyenIcon } from "./../../../assets/images/payments/adyen_logo.svg";
import LockIcon from "./../../../assets/icons/common/ic_lock.svg";
import "./payment-form-component.scss";
import { PaymentOptions } from "../../../common/enums/paymentOptions";
import { getAdyenConfiguration } from "../../../environments/adyen-config";
import { LocalStorageService } from "../../../services/local-storage.service";
import { updateCheckOutDataReducer } from "../../../store/shopping/slice";
import { useDispatch } from "react-redux";
import { waitingModalMessageTypes } from "../../../components/waiting-modal/waiting-modal-message-service";
import { Button } from "../../../components/button/button";
import { createImmutableStateInvariantMiddleware } from "@reduxjs/toolkit";
import { MAIN_ROUTE, PagesConstant } from "../../../common/constants/pages";
import { renderDocument } from "../../../services/contentful.service";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WarningIcon from "@mui/icons-material/Warning";
import { CircularProgress } from "@mui/material";
import { breakpoints } from "../../../common/constants/breakpoints";
import { useWindowSizeDetector } from "../../../hooks/useWindowSizeDetector";
import { reinitializePaymentStatus } from "../../../common/enums/reinitializePaymentStatus";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";

const PaymentFormComponent = ({
  checkOutData,
  contentfulAdyenComponent,
  setIdealPayment,
  setCreditCardPayment,
  contentfulButtons,
  queryParameters,
  finalisePayment,
  reinitializePayment,
  setReinitializePayment,
  finalisedPaymentData,
  setPaymentMethod,
  isVoucherApplied,
}) => {
  const paymentFormRef = useRef(null);
  const windowSizeDetector = useWindowSizeDetector();
  const dispatch = useDispatch();
  const [selectedPayment, setSelectedPayment] = useState(PaymentOptions.IDEAL);
  const [debounceWaiting, setDebounceWaiting] = useState(false);
  const debounceWaitTime = 500;
  const [debounceData, setDebounceData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [estimatedCosts, setEstimatedCosts] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isInsuranceSelected, setIsInsuranceSelected] = useState(false);
  const preusedPaymentData = useRef(null);

  useEffect(() => {
    if (checkOutData?.additionalProductInfo?.insuranceInfo) {
      setIsDataLoaded(true);
    }
  }, [checkOutData]);

  useEffect(() => {
    if (isDataLoaded) {
      const insuranceCompleted =
        checkOutData?.additionalProductInfo?.insuranceInfo
          ?.insuranceSelectionCompleted;
      console.log(checkOutData?.additionalProductInfo?.insuranceInfo);
      setIsInsuranceSelected(insuranceCompleted);
    }
  }, [isDataLoaded, checkOutData]);

  const adyenPaymentTypes = {
    ideal: "ideal",
    creditCard: "creditcard",
    scheme: "scheme",
  };

  useEffect(() => {
    windowSizeDetector.width < breakpoints.SM
      ? setIsMobile(true)
      : setIsMobile(false);
  });

  useEffect(() => {
    if (queryParameters) {
      if (Object.keys(queryParameters).length < 1) {
        initialisePayment();
      }
    }
  }, [queryParameters, selectedPayment]);

  useEffect(() => {
    if (queryParameters && queryParameters?.redirectResult) {
      finalisePayment();
    }
  }, [queryParameters]);

  useEffect(() => {
    if (finalisedPaymentData?.data?.paymentDetails?.paymentCancelled) {
      initialisePayment(true);
    }
  }, [selectedPayment]);

  useEffect(() => {
    if (!debounceWaiting && debounceData) {
      getCardCosts(debounceData);
    }
  }, [debounceWaiting, debounceData]);

  useEffect(() => {
    console.log(checkOutData.paymentInfo?.paymentSelected);
    const initialPaymentIsValid =
      (isInsuranceSelected || isVoucherApplied) &&
      queryParameters?.redirectResult &&
      (reinitializePayment === reinitializePaymentStatus.REINITIALIZE ||
        reinitializePayment === reinitializePaymentStatus.NOT_CONFIRMED);
    const reinitializedPaymentIsValid =
      (isInsuranceSelected || isVoucherApplied) &&
      !queryParameters?.redirectResult;

    if (initialPaymentIsValid || reinitializedPaymentIsValid) {
      retrievePresetPaymentData();
    }
  }, [isVoucherApplied, isInsuranceSelected, reinitializePayment]);

  const retrievePresetPaymentData = () => {
    if (!isLoading) {
      setIsLoading(true);
      if (selectedPayment === PaymentOptions.IDEAL) {
        handleIdealIssuerSelected(preusedPaymentData?.current);
      }
      if (selectedPayment === PaymentOptions.CARD) {
        handleCardNumberChanged(preusedPaymentData?.current);
      }
    }
  };

  useEffect(() => {
    if (reinitializePayment === reinitializePaymentStatus.NOT_CONFIRMED) {
      paymentFormRef.current.scrollIntoView({
        behavior: "auto",
        block: "end",
      });
    }
  }, [reinitializePayment]);

  const getCardCosts = async (paymentData) => {
    if (validateCreditCardFields(paymentData)) {
      const result = await GetCosts(
        checkOutData.checkOutKey,
        paymentData.paymentMethod.encryptedCardNumber
      );
      setEstimatedCosts(result.data.paymentCost);
      const selectionResult = await SelectPaymentMethod(
        checkOutData.checkOutKey,
        buildSelectPaymentDTO(paymentData)
      );
      dispatch(updateCheckOutDataReducer(selectionResult.data));
    }
  };

  const validateCreditCardFields = (paymentMethodData) => {
    if (
      paymentMethodData &&
      paymentMethodData.paymentMethod.encryptedCardNumber?.length &&
      paymentMethodData.paymentMethod.encryptedExpiryMonth?.length &&
      paymentMethodData.paymentMethod.encryptedExpiryYear?.length &&
      paymentMethodData.paymentMethod.encryptedSecurityCode?.length
    ) {
      return true;
    }
    return false;
  };

  const initialisePayment = async (shouldReinitialise) => {
    let sessionData;

    if (shouldReinitialise) {
      sessionData = { data: finalisedPaymentData.data.checkOutDetails };
    } else {
      sessionData = await InitiatePayment(checkOutData.checkOutKey);
    }

    const idealConfig =
      sessionData.data.paymentInfo.adyenPaymentMethodsData.paymentMethods.filter(
        (paymentOption) => paymentOption.type === PaymentOptions.IDEAL
      );

    const cardConfig =
      sessionData.data.paymentInfo.adyenPaymentMethodsData.paymentMethods.filter(
        (paymentOption) => paymentOption.type === PaymentOptions.CARD
      );

    let checkout = await AdyenCheckout(
      getAdyenConfiguration(
        process.env?.REACT_APP_ADYEN_CLIENT_ENVIRONMENT,
        process.env?.REACT_APP_ADYEN_CLIENT_KEY,
        LocalStorageService.getCulture(),
        sessionData,
        handleOnAdditionalDetails,
        handleOnChange
      )
    );

    const idealCheckout = await AdyenCheckout({
      apiKey: process.env?.REACT_APP_ADYEN_CLIENT_KEY,
      locale: LocalStorageService.getCulture(),
      paymentMethodsResponse:
        sessionData.data.paymentInfo.adyenPaymentMethodsData,
      paymentMethodsConfiguration: {
        ideal: idealConfig[0],
      },
      sessionData,
    });

    const configuration = {
      showPayButton: false,
      hasHolderName: true,
      positionHolderNameOnTop: true,
      holderNameRequired: true,
      billingAddressRequired: false,
      environment: "test",
      paymentMethodsResponse:
        sessionData.data.paymentInfo.adyenPaymentMethodsData,
      storedPaymentMethods: null,
      locale: LocalStorageService.getCulture(),
      clientKey: process.env?.REACT_APP_ADYEN_CLIENT_KEY,
      onAdditionalDetails: handleOnAdditionalDetails,
      onChange: handleOnChange,
      onPaymentCompleted: (result, component) => {
        console.info("COMPLETED", result, component);
      },
      onError: (error, component) => {
        console.error(error.name, error.message, error.stack, component);
      },
      styles: {
        base: {
          fontSize: "16px",
          fontWeight: "400",
        },
        placeholder: {
          fontWeight: "200",
        },
        brands: {
          height: "54px",
          width: "54px",
        },
        error: {
          color: "#001b2b",
        },
      },
    };

    if (selectedPayment === PaymentOptions.CARD) {
      const card = checkout
        .create(PaymentOptions.CARD, configuration)
        .mount("#card-container");
      setCreditCardPayment(card);
      setPaymentMethod(PaymentOptions.CARD);
    }

    if (selectedPayment !== PaymentOptions.CARD) {
      const idealComponent = idealCheckout
        .create(PaymentOptions.IDEAL, configuration)
        .mount("#ideal-container");
      setIdealPayment(idealComponent);
      setPaymentMethod(PaymentOptions.IDEAL);
    }

    dispatch(updateCheckOutDataReducer(sessionData.data));
  };

  const handlePaymentChange = (event) => {
    setSelectedPayment(event);
  };

  const handleOnChange = (state, component) => {
    if (state.data.paymentMethod.type === adyenPaymentTypes.scheme) {
      handleCardNumberChanged(state.data);
    }

    if (state.data.paymentMethod.type === adyenPaymentTypes.ideal) {
      handleIdealIssuerSelected(state.data);
    }
  };

  const handleIdealIssuerSelected = async (paymentData) => {
    preusedPaymentData.current = paymentData;
    if (!isLoading) setIsLoading(true);
    if (checkOutData?.checkOutKey) {
      try {
        const selectionResult = await SelectPaymentMethod(
          checkOutData.checkOutKey,
          buildSelectPaymentDTO(paymentData)
        );
        dispatch(updateCheckOutDataReducer(selectionResult.data));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCardNumberChanged = async (paymentData) => {
    if (!debounceWaiting) {
      setDebounceWaiting(true);
      setTimeout(() => {
        setDebounceWaiting(false);
      }, debounceWaitTime);
    }
    if (validateCreditCardFields(paymentData) && checkOutData?.checkOutKey) {
      preusedPaymentData.current = paymentData;
      if (!isLoading) setIsLoading(true);
      try {
        const selectionResult = await SelectPaymentMethod(
          checkOutData.checkOutKey,
          buildSelectPaymentDTO(paymentData)
        );
        dispatch(updateCheckOutDataReducer(selectionResult.data));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    setDebounceData(paymentData);
  };

  const buildSelectPaymentDTO = (paymentMethodData) => {
    if (validateCreditCardFields(paymentMethodData)) {
      return {
        selectedPaymentOptionCode: adyenPaymentTypes.creditCard,
        creditCardDetails: {
          browserInfoAcceptHeader: paymentMethodData.browserInfo?.acceptHeader,
          browserInfoUserAgent: paymentMethodData.browserInfo?.userAgent,
          encryptedCreditCardNumber:
            paymentMethodData.paymentMethod.encryptedCardNumber,
          encryptedExpiryMonth:
            paymentMethodData.paymentMethod.encryptedExpiryMonth,
          encryptedExpiryYear:
            paymentMethodData.paymentMethod.encryptedExpiryYear,
          encryptedSecurityCode:
            paymentMethodData.paymentMethod.encryptedSecurityCode,
        },
      };
    }
    if (
      paymentMethodData?.paymentMethod?.type === adyenPaymentTypes.ideal ||
      !paymentMethodData
    ) {
      return {
        selectedPaymentOptionCode: adyenPaymentTypes.ideal,
      };
    }
    return false;
  };

  const handleOnAdditionalDetails = (state, component) => {
    // state.data; // Provides the data that you need to pass in the `/payments/details` call.
    //   component; // Provides the active component instance that called this event.
  };

  const handleReinitializePayment = () => {
    setReinitializePayment(reinitializePaymentStatus.REINITIALIZE);
    initialisePayment(true);
  };

  const renderPaymentAttemptModal = () => {
    if (reinitializePayment !== reinitializePaymentStatus.NOT_CONFIRMED) return;
    return (
      <>
        <div className="disable-payment-form"></div>
        <div className="payment-attempt-component">
          <div className="payment-attempt-container">
            <div className="warning-icon">
              <AccessTimeIcon />
            </div>
            <div className="payment-attempt-description">
              {contentfulAdyenComponent &&
                renderDocument(
                  contentfulAdyenComponent[0]?.fields?.paymentAttemptDescription
                )}
              <Button
                className="payment-attempt-button"
                onClickAction={handleReinitializePayment}
              >
                {!isMobile &&
                  contentfulButtons &&
                  contentfulButtons[0]?.fields?.paymentAttemptButton}
                {isMobile &&
                  contentfulButtons &&
                  contentfulButtons[0]?.fields?.paymentAttemptMobile}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderInsuranceModal = () => {
    return (
      <>
        <div className="disable-payment-form"></div>
        <div className="payment-attempt-component">
          <div className="payment-attempt-container">
            <div className="information-icon">
              <WarningIcon />
            </div>
            <div className="payment-attempt-description">
              {contentfulAdyenComponent &&
                renderDocument(
                  contentfulAdyenComponent[0].fields
                    .paymentTermsAndConditionsWarrning
                )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="payment-header">
        {contentfulAdyenComponent &&
          contentfulAdyenComponent[0]?.fields?.adyenComponentTitle}
      </div>
      <div className="payment-form-component">
        {reinitializePayment === reinitializePaymentStatus.NOT_CONFIRMED &&
          renderPaymentAttemptModal()}
        {!isLoading && !isInsuranceSelected && renderInsuranceModal()}
        <div className="payment-form-container" ref={paymentFormRef}>
          <div className="payment-form-wrapper">
            <div className="payment-form-options">
              <label
                className="ideal-payment"
                onClick={() => handlePaymentChange(PaymentOptions.IDEAL)}
              >
                <div className="d-flex">
                  <div
                    className={
                      selectedPayment === PaymentOptions.IDEAL
                        ? "checkbox-container active"
                        : "checkbox-container inactive"
                    }
                  >
                    <div className="checkbox"></div>
                  </div>

                  <div className="ideal-info">
                    <div className="ideal-description">
                      {contentfulAdyenComponent &&
                        contentfulAdyenComponent[0]?.fields?.ideal}
                    </div>
                    <img
                      className="ideal-logo"
                      src={
                        GetAffiliate() === affiliates.klm
                          ? IdealIcon
                          : GetAffiliate() === affiliates.transavia
                          ? IdealIconTransavia
                          : null
                      }
                      alt="ideal"
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div>
                    <section className="spinner-container-payment">
                      {isLoading &&
                        selectedPayment === PaymentOptions.IDEAL && (
                          <CircularProgress
                            size={16}
                            className="loader-payment-form-ideal"
                          />
                        )}
                    </section>
                  </div>
                  <div className="free">
                    {contentfulAdyenComponent &&
                      contentfulAdyenComponent[0]?.fields?.gratis}
                  </div>
                </div>
              </label>
              {selectedPayment === PaymentOptions.IDEAL && (
                <div
                  className={
                    selectedPayment === PaymentOptions.IDEAL
                      ? "d-block"
                      : "d-none"
                  }
                >
                  <div id="ideal-container"></div>
                </div>
              )}
              <label
                className="credit-card-payment"
                onClick={() => handlePaymentChange(PaymentOptions.CARD)}
              >
                <div className="d-flex">
                  <div
                    className={
                      selectedPayment === PaymentOptions.CARD
                        ? "checkbox-container active"
                        : "checkbox-container inactive"
                    }
                  >
                    <div className="checkbox"></div>
                  </div>
                  <div className="credit-card-description-wrapper">
                    <div className="credit-card-description">
                      {contentfulAdyenComponent &&
                        contentfulAdyenComponent[0]?.fields?.card}
                    </div>
                    <div className="logo-container-wrapper">
                      <div className="logo-container-icon">
                        <img
                          className="visa-logo"
                          src={
                            GetAffiliate() === affiliates.klm
                              ? MasterCardIcon
                              : GetAffiliate() === affiliates.transavia
                              ? MasterCardIconTransavia
                              : null
                          }
                          alt="visa"
                        />
                      </div>
                      <div className="logo-container-icon">
                        <img
                          className="visa-logo"
                          src={
                            GetAffiliate() === affiliates.klm
                              ? VisaCardIcon
                              : GetAffiliate() === affiliates.transavia
                              ? VisaCardIconTransavia
                              : null
                          }
                          alt="visa"
                        />
                      </div>
                      <div className="logo-container-icon">
                        <img
                          className="visa-logo"
                          src={
                            GetAffiliate() === affiliates.klm
                              ? AmericanExpressIcon
                              : GetAffiliate() === affiliates.transavia
                              ? AmericanExpressIconTransavia
                              : null
                          }
                          alt="visa"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div>
                    <section className="spinner-container-payment">
                      {isLoading && selectedPayment === PaymentOptions.CARD && (
                        <CircularProgress
                          size={16}
                          className="loader-payment-form-ideal"
                        />
                      )}
                    </section>
                  </div>
                  {estimatedCosts !== null && estimatedCosts !== "" && (
                    <div className="up-to">€{estimatedCosts}</div>
                  )}
                  {estimatedCosts === null && (
                    <div className="up-to">
                      {contentfulAdyenComponent &&
                        contentfulAdyenComponent[0]?.fields?.additionalCosts}
                    </div>
                  )}
                  {estimatedCosts === "" && (
                    <div className="up-to">
                      {contentfulAdyenComponent &&
                        contentfulAdyenComponent[0]?.fields?.gratis}
                    </div>
                  )}
                </div>
              </label>
              {selectedPayment === PaymentOptions.CARD && (
                <div
                  className={
                    selectedPayment === PaymentOptions.CARD
                      ? "d-block"
                      : "d-none"
                  }
                >
                  <div id="card-container"></div>
                </div>
              )}
            </div>
          </div>
          <div className="adyen-info-container">
            <div className="adyen-info-wrapper">
              <img src={LockIcon} alt="lock-icon" />
              <div className="adyen-info-description">
                {contentfulAdyenComponent &&
                  contentfulAdyenComponent[0]?.fields?.securedPaymentWith}
              </div>
              <AdyenIcon className="adyen-icon" />
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};
export default PaymentFormComponent;
