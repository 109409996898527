import "./phone-number-input.component.scss";
import { Controller } from "react-hook-form";
import { PassengerDetailsFormFields } from "../../../../../common/constants/passengerDetailsFormFields";
import { InputComponent } from "../../../../../components/input-component/input-component";
import React, { useState } from "react";
import { FormInputDropdownComponent } from "../../../../../components/form-input-dropdown/form-input-dropdown.component";
import PhoneNumber from "libphonenumber-js";
import { ValidationPattern } from "../../../../../common/enums/validationRulesEnum";

export const PhoneNumberInputComponent = ({
  phoneCodes,
  defaultPhoneCode,
  defaultPhoneSuffix,
  name,
  control,
  errors,
  contentfulCheckoutPassengerDetails,
  contentfulCheckoutFormValidators,
}) => {
  const [countryPrefix, setCountryPrefix] = useState(phoneCodes[0]);
  const {
    phoneNumberCountryCode: phoneCountryCodeError,
    phoneNumberSuffix: phoneNumberError,
  } = errors[name] ?? "";

  return (
    <div className="phone-number-component">
      <div className="phone-number-wrapper">
        <div className="country-code">
          <Controller
            name={`${name}.${PassengerDetailsFormFields.PHONE_COUNTRY_CODE}`}
            control={control}
            defaultValue={phoneCodes[0]}
            rules={{
              required: {
                value: true,
                message:
                  contentfulCheckoutFormValidators &&
                  contentfulCheckoutFormValidators[0]?.fields
                    ?.phoneCountryCodeRequired,
              },
            }}
            render={({ field, fieldState }) => (
              <div className="">
                <FormInputDropdownComponent
                  name={`${name}.${PassengerDetailsFormFields.PHONE_COUNTRY_CODE}`}
                  label={
                    contentfulCheckoutPassengerDetails &&
                    contentfulCheckoutPassengerDetails[0]?.fields
                      ?.countryPhonePrefix
                  }
                  defaultValue={phoneCodes[0]}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  isError={fieldState.error}
                  inputOptions={phoneCodes}
                  setStateUp={setCountryPrefix}
                  isStateLifted={true}
                />
              </div>
            )}
          />
        </div>
        <div className="phone-number">
          <label className="phone-number-label">
            <Controller
              name={`${name}.${PassengerDetailsFormFields.PHONE_NUMBER}`}
              control={control}
              defaultValue={defaultPhoneSuffix}
              rules={{
                required: {
                  value: true,
                  message:
                    contentfulCheckoutFormValidators &&
                    contentfulCheckoutFormValidators[0]?.fields
                      ?.phoneNumberInvalid,
                },
                validate: (value) => {
                  try {
                    const isDigitsOnly = ValidationPattern.ONLY_DIGITS.test(value);
                    if (
                      value.length <
                        PassengerDetailsFormFields.NINE_DIGIT_PHONE_NUMBER ||
                      (value.length <
                        PassengerDetailsFormFields.TEN_DIGIT_PHONE_NUMBER &&
                        value.startsWith("0")) || !isDigitsOnly
                    ) {
                      return (
                        contentfulCheckoutFormValidators &&
                        contentfulCheckoutFormValidators[0]?.fields
                          ?.phoneNumberInvalid
                      );
                    }

                    const fullPhoneNumber = countryPrefix + value;
                    const phoneNumber = PhoneNumber(fullPhoneNumber, "ZZ");

                    if (!phoneNumber.isValid()) {
                      return (
                        contentfulCheckoutFormValidators &&
                        contentfulCheckoutFormValidators[0]?.fields
                          ?.phoneNumberInvalid
                      );
                    }
                  } catch (error) {
                    return (
                      contentfulCheckoutFormValidators &&
                      contentfulCheckoutFormValidators[0]?.fields
                        ?.phoneNumberInvalid
                    );
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <div className="phone-number-content">
                  <InputComponent
                    label={
                      contentfulCheckoutPassengerDetails &&
                      contentfulCheckoutPassengerDetails[0]?.fields?.phoneNumber
                    }
                    name={`${name}.${PassengerDetailsFormFields.PHONE_NUMBER}`}
                    defaultValue=""
                    value={field.value}
                    onChange={field.onChange}
                    isError={fieldState.error}
                    placeholder="0612345678"
                    type="tel"
                    pattern="[0-9]*" 
                    inputMode="numeric"
                  />
                </div>
              )}
            />
          </label>
        </div>
      </div>

      {(phoneCountryCodeError || phoneNumberError) && (
        <div className="error-message-placeholder">
          <span className="error error-message">
            {(phoneCountryCodeError && phoneCountryCodeError?.message) ||
              (phoneNumberError && phoneNumberError?.message)}
          </span>
        </div>
      )}
    </div>
  );
};
