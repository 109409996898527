import { useEffect, useState, forwardRef } from "react";
import { useWindowSizeDetector } from "../../../hooks/useWindowSizeDetector";
import { breakpoints } from "../../../common/constants/breakpoints";
import { filterType } from "../../../common/constants/filterType";
import { FilterChipComponent } from "./filter-chip/filter-chip-component";
import { methods } from "../filters.methods";
import { useSearchParams } from "react-router-dom";
import { LocalStorageService } from "../../../services/local-storage.service";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";
import { searchParamsEnum } from "../../../common/enums/searchParamsEnum";
import { action } from "../../../common/constants/action";
import "./selected-filters.component.scss";

export const SelectedFilterComponent = forwardRef(
  (
    {
      holidayFullData,
      contentfulButtons,
      contentfulFilters,
      performSearch,
      performFilter,
    },
    ref
  ) => {
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [holidayListKey, setStateHolidaysListKey] = useState("");
    const [destinationFilterChips, setStateDestinationFilterChips] =
      useState([]);
    const [genericFilterChips, setStateGenericFilterChips] = useState(null);
    const [requestedFilters, setStateRequestedFilters] = useState(null);
    const [numberOfFiltersApplied, setStateNumberOfFiltersApplied] = 
      useState(0);
    const [displayedFilterCount, setDisplayedFilterCount] = useState(0);
    const windowSize = useWindowSizeDetector();
    const [searchParams, setSearchParams] = useSearchParams();
    const maximumNumberOfCountries = 3;

    useEffect(() => {
      setIsMobile(windowSize.width <= breakpoints.SM);
      setIsTablet(
        windowSize.width > breakpoints.SM && windowSize.width < breakpoints.XL
      );
    }, [windowSize.width]);

    useEffect(() => {
      if (holidayFullData) {
        setStateHolidaysListKey(holidayFullData?.data?.holidayListKey);
        
        const allDestinationFilters = holidayFullData?.data?.filterInfo?.destinationFilterChips || [];
        
        const visibleDestinationFilters = allDestinationFilters.slice(0, maximumNumberOfCountries);
        setStateDestinationFilterChips(visibleDestinationFilters);
        
        setStateGenericFilterChips(
          holidayFullData?.data?.filterInfo?.genericFilterChips || []
        );
        
        setStateRequestedFilters(
          holidayFullData?.data?.filterInfo?.requestedFilters
        );
        
        setStateNumberOfFiltersApplied(holidayFullData?.data?.filterInfo?.numberOfFiltersApplied || 0);
        
        const genericFiltersCount = (holidayFullData?.data?.filterInfo?.genericFilterChips || []).length;
        const visibleDestinationCount = visibleDestinationFilters.length;
        setDisplayedFilterCount(genericFiltersCount + visibleDestinationCount);

        const destinationFilterValues =
          allDestinationFilters
            .map((destination) => destination.destinationCode)
            .join(",");

        if (destinationFilterValues?.length > 0) {
          setSearchParams((params) => {
            params.set(
              searchParamsEnum.destinationLocation,
              destinationFilterValues
            );
            return params;
          });
          LocalStorageService.setLocalStorageItem(
            LocalStorageEnum.DESTINATION_LOCATIONS,
            destinationFilterValues
          );
        } else {
          setSearchParams((params) => {
            params.delete(searchParamsEnum.destinationLocation);
            return params;
          });
          LocalStorageService.setLocalStorageItem(
            LocalStorageEnum.DESTINATION_LOCATIONS,
            ""
          );
        }
      }
    }, [holidayFullData]);
    
    const performFilterAction = (filter) => {
      if (filter?.type === filterType.destination && filter?.value === null) {
        setSearchParams((params) => {
          params.delete(searchParamsEnum.destinationLocation);
          return params;
        });
        performSearch(methods.setSearchRequestBody(holidayFullData, null, ""));
        return;
      } else if (filter && filter.type === filterType.destination) {
        const allDestinationFilters = holidayFullData?.data?.filterInfo?.destinationFilterChips || [];
        const filteredDestinations = allDestinationFilters
          .map((destination) => {
            if (destination.destinationCode !== filter.value) {
              return destination.destinationCode;
            }
            return null;
          })
          .filter((destination) => destination !== null && destination !== undefined)
          .join(",");
    
        if (filteredDestinations.length === 0) {
          setSearchParams((params) => {
            params.set(searchParamsEnum.destinationLocation, "");
            return params;
          });
        } else {
          setSearchParams((params) => {
            params.set(searchParamsEnum.destinationLocation, filteredDestinations);
            return params;
          });
        }
    
        performSearch(
          methods.setSearchRequestBody(holidayFullData, requestedFilters, filter)
        );
      } else if (filter && filter.type === filterType.generic) {
        performFilter(
          methods.setDeleteFilterRequestBody(
            holidayListKey,
            requestedFilters,
            filter
          )
        );
      } else {
        if (filter.action === action.delete) {
          setSearchParams((params) => {
            const destinationParamsValue = params.get(searchParamsEnum.destinationLocation);
            if (!destinationParamsValue) return params;
            
            const destinationParams = destinationParamsValue
              .split(",")
              .filter((param) => param !== filter.value)
              .join(",");
    
            params.set(searchParamsEnum.destinationLocation, destinationParams);
            LocalStorageService.setLocalStorageItem(
              LocalStorageEnum.DESTINATION_LOCATIONS,
              destinationParams
            );
            return params;
          });
        }
        performSearch(methods.setSearchRequestBody(holidayFullData));
      }
    };    

    const clearAllFilters = () => {
      setStateHolidaysListKey(null);
      setStateDestinationFilterChips([]);
      setStateGenericFilterChips([]);
      setStateRequestedFilters(null);
      setStateNumberOfFiltersApplied(0);
      setDisplayedFilterCount(0);
    };

    return (
      <div className="selected-filters-component" ref={ref}>
        {!isTablet && !isMobile && (
          <div className="selected-filters-header">
            {displayedFilterCount || 0}{" "}
            {contentfulFilters && contentfulFilters[0]?.fields?.filtersSelected}
          </div>
        )}
        <div className="selected-filters-container">
          {destinationFilterChips?.map((filter, index) => (
            <FilterChipComponent
              key={index}
              data={filter}
              contentfulFilters={contentfulFilters}
              performFilterAction={performFilterAction}
            />
          ))}
          {genericFilterChips?.map((filter, index) => (
            <FilterChipComponent
              key={index}
              data={filter}
              contentfulFilters={contentfulFilters}
              performFilterAction={performFilterAction}
            />
          ))}
          {requestedFilters && displayedFilterCount > 0 ? (
            <div
              onClick={() => {
                performFilterAction({
                  type: filterType.destination,
                  value: null,
                  text: "",
                  action: action.delete,
                });
                clearAllFilters();
              }}
              className="delete-all-filter-chips"
            >
              {contentfulButtons?.length > 0 &&
                contentfulButtons[0]?.fields?.clearAllFilters}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);