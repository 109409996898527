import { useEffect, useState } from "react";
import { Accordion } from "../../accordion/accordion";
import { StarRatingComponent } from "../../star-rating/star-rating.component";
import { filterType } from "../../../common/constants/filterType";
import "./star-rating-filter.component.scss";

export const StarRatingFilterComponent = ({
  contentfulFilters,
  data,
  setGenericFilter,
}) => {
  const [starRatingFilterOptions, setStateStarRatingFilterOptions] = useState(
    []
  );

  useEffect(() => {
    setStateStarRatingFilterOptions(data);
  }, [data]);

  const handleOnChange = (index) => {
    let output = starRatingFilterOptions;
    output[index].selected = !output[index].selected;
    const genericFilterValue = output
      .filter((item) => item.selected === true)
      .map((item) => item.code);
    setGenericFilter([
      {
        stars: genericFilterValue,
      },
    ]);
    setStateStarRatingFilterOptions(output);
  };

  return (
    starRatingFilterOptions && (
      <div className="star-rating-filter-component-container">
        <Accordion
          startingExpandedState="false"
          labelText={
            contentfulFilters
              ? contentfulFilters[0]?.fields?.numberOfStars
              : "Number of stars"
          }
        >
          <div className="filter-options-container">
            {data?.map((element, index) => {
              return (
                <div
                  key={index}
                  className="filter-option"
                  onClick={() => [handleOnChange(index)]}
                >
                  <div
                    className={
                      starRatingFilterOptions[index]?.selected
                        ? "checkbox-container active"
                        : "checkbox-container inactive"
                    }
                  >
                    <div className="checkbox"></div>
                  </div>

                  <div className="star-rating-container">
                    <StarRatingComponent data={parseInt(element.code)} />
                  </div>

                  <div className="counter-container">{element.count}</div>
                </div>
              );
            })}
          </div>
        </Accordion>
      </div>
    )
  );
};
