import { useState, useEffect, useReducer, useContext } from "react";
import { Box, Modal, LinearProgress } from "@mui/material";
import "./waiting-modal.component.scss";
import ContentfulContext from "../../store/contentful/contentful-context";
import {
  getCustomStyling,
  getModalWithAnimationStyling,
  getProgressBarStyling,
} from "../../assets/custom-styling/custom-modal";
import KlmHorozintalLogo from "../../assets/icons/klm/holidays-horizontal.svg";
import KlmWorldLogo from "../../assets/icons/klm/klm-world.svg";
import TransaviaModalLogo from "../../assets/icons/transavia/transavia-holiday-logo.png";
import { GetAffiliate } from "../../services/general.service";
import { affiliates } from "../../common/enums/affiliates";
import { contentfulEntriesReducer } from "../../common/enums/contentfulEntriesReducer";
import { useDispatch, useSelector } from "react-redux";
import { getContentfulByContentType } from "../../services/contentful.service";
import { contentType } from "../../common/constants/contentType";

const initialState = {
  contentfulButtons: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case contentfulEntriesReducer.WAITING_MODAL:
      return { ...state, contentfulWaitingModal: action.payload };
    case contentfulEntriesReducer.WAITING_MODAL_MESSAGE:
      return { ...state, contentfulWaitingModalMessage: action.payload };
    default:
      throw new Error("Unknown data");
  }
};

export const WaitingModalComponent = ({ waitingScreenMessage, isMobile }) => {
  const contentfulEntries = useContext(ContentfulContext);
  const [
    { contentfulWaitingModal, contentfulWaitingModalMessage },
    localDispatch,
  ] = useReducer(reducer, initialState);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentMessage, setCurrentMessage] = useState();

  useEffect(() => {
    const contentfulWaitingModalData = getContentfulByContentType(
      contentfulEntries,
      contentType.WAITING_MODAL
    );
    const contentfulWaitingModalMessageData = getContentfulByContentType(
      contentfulEntries,
      contentType.WAITING_MODAL_MESSAGE
    );
    localDispatch({
      type: contentfulEntriesReducer.WAITING_MODAL,
      payload: contentfulWaitingModalData,
    });
    localDispatch({
      type: contentfulEntriesReducer.WAITING_MODAL_MESSAGE,
      payload: contentfulWaitingModalMessageData,
    });
  }, [contentfulEntries]);

  useEffect(() => {
    setLoadingProgress(0);
    if (
      contentfulWaitingModal &&
      contentfulWaitingModalMessage &&
      !!waitingScreenMessage
    ) {
      setCurrentMessage(
        contentfulWaitingModalMessage.filter(
          (msg) => msg.fields.messageType === waitingScreenMessage
        )[0]?.fields?.messageText
      );

      const waitingModalData = contentfulWaitingModal.filter(
        (modal) =>
          modal?.fields?.modalMessage[0]?.fields?.messageType ===
          waitingScreenMessage
      );
      if (waitingModalData) {
        if (
          isMobile &&
          waitingModalData[0]?.fields?.mobileBackgroundImage?.length
        ) {
          setBackgroundImage(
            waitingModalData[0]?.fields?.mobileBackgroundImage
          );
        } else {
          setBackgroundImage(waitingModalData[0]?.fields?.backgroundImage);
        }
        simulateOngoingProgress();
      }
    }
  }, [waitingScreenMessage, contentfulWaitingModal]);

  useEffect(() => {
    if (backgroundImage) {
      const intervalId = setInterval(() => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % backgroundImage?.length
        );
      }, 3000);

      return () => clearInterval(intervalId);
    }
  }, [backgroundImage]);

  const simulateOngoingProgress = () => {
    const progressStepSize = 1;
    const interval = setInterval(() => {
      setLoadingProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + progressStepSize;
      });
    }, 200);

    return () => {
      clearInterval(interval);
      setLoadingProgress(0);
    };
  };

  const waitingModal = backgroundImage?.length ? (
    <Modal
      className="animated-waiting-modal"
      open={!!waitingScreenMessage}
      aria-labelledby="waiting-modal-title"
      aria-describedby="waiting-modal-description"
    >
      <Box
        sx={() =>
          getModalWithAnimationStyling(
            isMobile,
            backgroundImage[currentImageIndex]?.fields?.file
          )
        }
      >
        {contentfulWaitingModal[0]?.fields?.animation && (
          <img
            src={
              contentfulWaitingModal[0]?.fields?.animation?.fields?.file?.url
            }
            alt={contentfulWaitingModal[0]?.fields?.animation?.fields?.title}
          />
        )}
        <span className="waiting-text">{currentMessage}</span>
        <LinearProgress
          className="waiting-modal-progress-bar"
          variant="determinate"
          value={loadingProgress}
          sx={() => getProgressBarStyling()}
        />
      </Box>
    </Modal>
  ) : (
    <Modal
      className="waiting-modal"
      open={!!waitingScreenMessage}
      aria-labelledby="waiting-modal-title"
      aria-describedby="waiting-modal-description"
    >
      <Box sx={() => getCustomStyling(isMobile)}>
        <div className="waiting-modal-body">
          <div className="container-logo">
            {GetAffiliate() === affiliates.klm ? (
              <>
                <img
                  className="container-logo__logo-klm"
                  src={KlmHorozintalLogo}
                  alt="logo klm"
                />
                <img
                  src={KlmWorldLogo}
                  alt="logo world"
                  className="word-icon"
                />
              </>
            ) : GetAffiliate() === affiliates.transavia ? (
              <img src={TransaviaModalLogo} className="modal-icon" />
            ) : null}
          </div>
          <span className="waiting-text">{currentMessage}</span>
        </div>
      </Box>
    </Modal>
  );

  return currentMessage && waitingModal;
};
