import React, { useCallback } from "react";
import "./holiday-card.styles.scss";
import { Button } from "../button/button";
import { HolidayIconBox } from "./holiday-icon-box/holiday-icon-box";
import { MdMap } from "react-icons/md";
import { HotelComponent } from "../hotel/hotel.component";
import { HotelLocationComponent } from "../hotel/hotel-location/hotel-location.component";
import { HotelStarRatingComponent } from "../hotel/hotel-star-rating/hotel-star-rating.component";
import { HotelTripadvisorRatingComponent } from "../hotel/hotel-tripadvisor-rating/hotel-tripadvisor-rating.component";
import { HotelAmenitiesComponent } from "../hotel/hotel-amenities/hotel-amenities.component";
import { HolidayThemesComponent } from "./holiday-themes/holiday-themes.component";
import { HotelImageFlipper } from "../hotel/hotel-image-flipper/hotel-image-flipper.component";
import { useState, useRef, useEffect } from "react";
import {
  formatContentfulImageUrl,
  getContentfulByContentType,
  parseContentfulDynamicContent,
} from "../../services/contentful.service";
import { GetMealType } from "../../common/enums/meal-types";
import transaviaMapIcon from "../../assets/icons/transavia/map-icon.svg";
import { GetAffiliate } from "../../services/general.service";
import { contentType } from "../../common/constants/contentType";
import { affiliates } from "../../common/enums/affiliates";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import { breakpoints } from "../../common/constants/breakpoints";

export const HolidayCardComponent = ({
  holidayDetails,
  minLowerCardWidth,
  setMinLowerCardWidth,
  lastWindowWidth,
  contentfulEntries,
  onMapClick,
  contentfulButtons,
}) => {
  const lowerCardContainer = useRef(null);
  const [contentfulHolidaysComponent, setContentfulHolidaysComponent] =
    useState("");
  const [marketingRibbonUrl, setMarketingRibbonUrl] = useState("");
  const [showSkeleton, setStateShowSkeleton] = useState(true);
  const windowSize = useWindowSizeDetector();

  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    windowSize.width > breakpoints.SM ? setIsMobile(false) : setIsMobile(true);
    windowSize.width > breakpoints.SM && windowSize.width < breakpoints.LG
      ? setIsTablet(true)
      : setIsTablet(false);
  }, [windowSize.width]);

  const navigateToHolidayPage = () => {
    window.location.href = `${holidayDetails?.holidayPageLink}`;
  };

  const handleResize = () => {
    if (
      !showSkeleton &&
      (lowerCardContainer?.current.offsetWidth < minLowerCardWidth ||
        minLowerCardWidth === 0 ||
        lastWindowWidth === 0 ||
        lastWindowWidth < window.innerWidth)
    ) {
      setMinLowerCardWidth(lowerCardContainer.current.offsetWidth);
    }
  };

  useEffect(() => {
    const contentfulHolidaysComponentResponse = getContentfulByContentType(
      contentfulEntries,
      contentType.HOLIDAYS_COMPONENT
    );
    setContentfulHolidaysComponent(contentfulHolidaysComponentResponse);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [contentfulEntries]);

  useEffect(() => {
    setMarketingRibbonUrl(
      formatContentfulImageUrl(
        contentfulHolidaysComponent &&
          contentfulHolidaysComponent[0]?.fields?.ribbonRecommended?.fields
            ?.file?.url
      )
    );
  });

  const handleOnMapClick = () => {
    onMapClick(holidayDetails);
  };

  // This is just a temporary solution to mimic a reaction to an async API response (filters)
  useEffect(() => {
    setTimeout(() => {
      setStateShowSkeleton(false);
      setMinLowerCardWidth(lowerCardContainer?.current?.offsetWidth);
    }, 500);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showSkeleton]);

  const summaryText = useCallback(() => {
    return (
      <span className="tiny-text holiday-summary-text">
        {GetAffiliate() === affiliates.transavia &&
          contentfulHolidaysComponent && (
            <div className="holiday-summary-text-departure-airport">{`${contentfulHolidaysComponent[0]?.fields?.fromOriginText} ${holidayDetails?.summary?.departureAirport}`}</div>
          )}
        <div className="holiday-summary-text-departure-date">
          {holidayDetails?.summary?.displayDepartureDate}
        </div>
        <div className="holiday-summary-text-duration">
          {holidayDetails?.summary?.tripDuration}{" "}
          {contentfulHolidaysComponent &&
            contentfulHolidaysComponent[0]?.fields?.daysText}{" "}
          ({holidayDetails?.summary?.numberOfNights}{" "}
          {contentfulHolidaysComponent &&
            contentfulHolidaysComponent[0]?.fields?.nightsText}
          )
        </div>
        <div className="holiday-summary-text-meal">
          {GetMealType(holidayDetails?.summary?.mealType)}
        </div>
      </span>
    );
  }, [holidayDetails, contentfulHolidaysComponent]);
  const [tooltipVisible, setTooltipVisible] = useState({});

  const handleMouseOver = (key) => {
    setTooltipVisible((prevState) => ({ ...prevState, [key]: true }));
  };

  const handleMouseOut = (key) => {
    setTooltipVisible((prevState) => ({ ...prevState, [key]: false }));
  };

  const standardSummaryBlock = (
    <div className="holiday-card-action-block">
      <div
        className={`holiday-card-action-block-wrapper ${
          holidayDetails?.summary?.discountPerPerson ? "two-items" : "one-item"
        }`}
      >
        {contentfulEntries && holidayDetails?.summary?.discountPerPerson && (
          <div className="holiday-card-discount-alert">
            {contentfulHolidaysComponent &&
              contentfulHolidaysComponent[0]?.fields?.inclusiveText}
            {"€ "}
            {holidayDetails?.summary?.discountPerPerson + " "}
            {contentfulHolidaysComponent &&
              contentfulHolidaysComponent[0]?.fields?.discountText}
            {contentfulEntries && holidayDetails?.summary?.perPersonText}
          </div>
        )}
        <div className="holiday-card-price-box">
          <span className="medium-text-aligned-end holiday-price-descriptor-text">
            {contentfulHolidaysComponent &&
              contentfulHolidaysComponent[0]?.fields?.priceFromText}
          </span>
          <span className="holiday-price-text">
            {"€ "}
            {holidayDetails?.summary?.pricePerPerson}
          </span>
          <div className="medium-text-aligned-end holiday-price-descriptor-text per-person-text">
            {contentfulHolidaysComponent &&
              contentfulHolidaysComponent[0]?.fields?.perPersonText}
          </div>
        </div>
      </div>
      <Button
        buttonText={
          contentfulButtons && contentfulButtons[0]?.fields?.lookAtHoliday
        }
        className="button-accept"
        onClickAction={navigateToHolidayPage}
      />
      <div className="local-fee-description">
        {contentfulHolidaysComponent &&
          parseContentfulDynamicContent(
            contentfulHolidaysComponent[0]?.fields?.priceCostSummary,
            holidayDetails?.summary?.additionalCost
          )}
      </div>
    </div>
  );

  const HolidayCardComponentElements = (
    <>
      {holidayDetails?.hotel?.images && (
        <HotelImageFlipper
          hotelImages={holidayDetails?.hotel?.images}
          contentfulData={contentfulHolidaysComponent}
          marketingRibbonTag={holidayDetails?.marketingRibbon ?? ""}
        />
      )}
      {/* Holiday card primary content */}
      <div className="holiday-card-primary-content">
        {/* Hotel details */}
        <div className="holiday-card-hotel-details-container">
          <div className="holiday-card-hotel-name">
            <HotelComponent
              isMobile={isMobile}
              setIsMobile={setIsMobile}
              data={holidayDetails}
              maxComponentWidth={minLowerCardWidth}
            />
          </div>
          <div className="holiday-card-hotel-location">
            <HotelLocationComponent data={holidayDetails} showFlag={true} />
          </div>
          <div className="holiday-card-hotel-rating">
            <HotelStarRatingComponent
              starRating={holidayDetails.hotel?.rating?.starRating}
            />
            <HotelTripadvisorRatingComponent data={holidayDetails} />
          </div>
          <div className="holiday-card-hotel-amenities">
            <HotelAmenitiesComponent
              data={holidayDetails}
              contentfulEntries={contentfulHolidaysComponent}
            />
          </div>
        </div>
        {/* Themes and map */}
        <div className="holiday-card-lower-container-spacer"></div>
        <div ref={lowerCardContainer} className="holiday-card-lower-container">
          <div className="holiday-card-themes">
            <HolidayThemesComponent holidayDetails={holidayDetails} />
          </div>
          <button className="holiday-card-map-view" onClick={handleOnMapClick}>
            {GetAffiliate() === affiliates.transavia && (
              <img
                className="holiday-card-map-view-icon icon-box map-icon-box"
                src={transaviaMapIcon}
              />
            )}
            {GetAffiliate() !== affiliates.transavia && (
              <MdMap className="holiday-card-map-view-icon icon-box map-icon-box" />
            )}
            {contentfulHolidaysComponent &&
              contentfulHolidaysComponent[0]?.fields?.mapText}
          </button>
        </div>
      </div>
      {/* Holiday card summary */}
      <div className="holiday-card-summary">
        <div className="summary-and-icon-wrapper">
          {!isMobile && <HolidayIconBox />}
          {!isMobile && summaryText()}
        </div>
        {isMobile && (
          <div className="holiday-card-mobile-summary">
            {summaryText()}
            <HolidayIconBox />
          </div>
        )}
        {!holidayDetails?.summary?.discountPerPerson && (
          <div className="spacer-block"></div>
        )}{" "}
        {!isMobile && standardSummaryBlock}
      </div>

      {isMobile && (
        <div className="holiday-card-action-block">
          <div className="holiday-card-price-box">
            <div className="holiday-card-price-wrapper">
              <span className="medium-text-aligned-end holiday-price-descriptor-text">
                {contentfulHolidaysComponent &&
                  contentfulHolidaysComponent[0]?.fields?.priceFromText}{" "}
              </span>
              <span className="holiday-price-text">
                {"€ "} {holidayDetails?.summary?.pricePerPerson}
              </span>
              <span className="medium-text-aligned-end-aligned-end holiday-price-descriptor-text per-person-text">
                {contentfulHolidaysComponent &&
                  contentfulHolidaysComponent[0]?.fields?.perPersonText}
              </span>
            </div>
            {contentfulEntries && holidayDetails?.summary?.discountPerPerson && (
              <div className="holiday-card-discount-alert">
                {contentfulHolidaysComponent &&
                  contentfulHolidaysComponent[0]?.fields?.inclusiveText}
                {"€ "}
                {holidayDetails?.summary?.discountPerPerson + " "}
                {contentfulHolidaysComponent &&
                  contentfulHolidaysComponent[0]?.fields?.discountText}
                {contentfulEntries && holidayDetails?.summary?.perPersonText}
              </div>
            )}
          </div>
          <Button
            buttonText={
              contentfulButtons && contentfulButtons[0]?.fields?.lookAtHoliday
            }
            className="button-accept button-mobile-spacing"
            onClickAction={navigateToHolidayPage}
          />
          <div className="local-fee-description">
            {contentfulHolidaysComponent &&
              parseContentfulDynamicContent(
                contentfulHolidaysComponent[0]?.fields?.priceCostSummary,
                holidayDetails?.summary?.additionalCost
              )}
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className="holiday-card-component">{HolidayCardComponentElements}</div>
  );
};
