import config from "../../../../environments/config.json";
import { filterType } from "../../../../common/constants/filterType";
import { action } from "../../../../common/constants/action";
import "./filter-chip-component.scss";
import { GetAmenityDescription } from "../../../../common/enums/amenities";

export const FilterChipComponent = ({
  data,
  contentfulFilters,
  performFilterAction,
  isFilterChipAction = false,
  performFilterChipAction,
}) => {
  const setFilterInfo = () => {
    if (data.destinationCode) {
      const output = {
        type: filterType.destination,
        value: data.destinationCode,
        text: data.displayName,
        action: action.delete,
      };
      return output;
    } else {
      const output = {
        type: filterType.generic,
        value: getFilterTypeValue(data.filterType),
        text: data.displayText,
        action: action.delete,
      };
      return output;
    }
  };

  const getFilterTypeValue = (selectedFilterType) => {
    return selectedFilterType === filterType.allInclusive
      ? filterType.selectedAllInclusive
      : data.filterType;
  };

  const setFilterChipText = () => {
    if (data.destinationCode) {
      return data.displayName;
    } else {
      if (data.filterType === filterType.stars) {
        return contentfulFilters
          ? data.displayText +
              " " +
              contentfulFilters[0]?.fields?.filterStarRatingText
          : data.displayText + " " + filterType.starsText;
      } else if (data.filterType === filterType.minTripAdvisorRating) {
        if (data.displayText === filterType.veryGood) {
          return contentfulFilters
            ? contentfulFilters[0]?.fields?.fourPointRatingText +
                " (" +
                data.displayText +
                "+)"
            : filterType.veryGoodText + " (" + data.displayText + "+)";
        } else if (data.displayText === filterType.excellent) {
          return contentfulFilters
            ? contentfulFilters[0]?.fields?.fivePointRatingText +
                " (" +
                data.displayText +
                ")"
            : filterType.excellentText + " (" + data.displayText + ")";
        }
      } else if (data.filterType === filterType.distanceToCenter) {
        return contentfulFilters
          ? contentfulFilters[0]?.fields?.toCentre + ": " + data.displayText
          : filterType.toCentre + ": " + data.displayText;
      } else if (data.filterType === filterType.amenity) {
        return contentfulFilters
          ? GetAmenityDescription(data.displayText)
          : data.displayText;
      } else {
        return data.displayText;
      }
    }
  };

  const filterChipText = <>{setFilterChipText()}</>;

  return (
    <div className="filter-chip-component">
      {data.countryCode && (
        <div className="flag-container">
          <img
            className="flag-small"
            alt={data.countryCode}
            src={`${
              config.COUNTRY_FLAGS
            }/${data.countryCode.toLowerCase()}.png`}
          />
        </div>
      )}
      <div className="text-container">{filterChipText}</div>
      <div className="button-container">
        <div
          className="button-filter-action"
          onClick={() => {
            !isFilterChipAction
              ? performFilterAction(setFilterInfo())
              : performFilterChipAction(data);
          }}
        ></div>
      </div>
    </div>
  );
};
