import { PagesConstant } from "../../../common/constants/pages";
import { getCheckOutDetailsCart } from "../../../store/shopping/slice";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";
import "./check-availability.helper.scss";

const inputData = {
  holidayKey: null,
  metaInfo: null,
  priceDetailInfo: null,
  contentfulCheckAvailability: null,
  showCustomModal: null,
  hideCustomMobile: null,
};

const setCheckAvailabilityModalContent = (
  header,
  info,
  buttonReturnText,
  buttonContinueText,
  priceChange,
  oldTotalPriceText,
  oldTotalPrice,
  newTotalPriceText,
  newTotalPrice,
  handleContinue
) => {
  return (
    <div className="checkAvailabilityModalContent">
      <div className="checkAvailabilityModalContent-header">{header}</div>
      <div className="checkAvailabilityModalContent-info">{info}</div>
      {priceChange && (
        <div className="checkAvailabilityModalContent-price-change">
          <div>{oldTotalPriceText}</div>
          <div>€&nbsp;{oldTotalPrice}</div>
          <div>{newTotalPriceText}</div>
          <div>€&nbsp;{newTotalPrice}</div>
        </div>
      )}
      <div className="checkAvailabilityModalContent-buttons">
        {buttonReturnText && (
          <div onClick={inputData.hideCustomMobile} className="return">
            {buttonReturnText}
          </div>
        )}
        {buttonContinueText && (
          <div onClick={handleContinue} className="continue">
            {buttonContinueText}
          </div>
        )}
      </div>
    </div>
  );
};

const processCheckAvailabilityResponse = (
  checkAvailabilityResponse,
  contentfulCheckAvailability,
  showCustomModal,
  hideCustomMobile,
  dispatch
) => {
  if (typeof dispatch !== "function") {
    return;
  }

  localStorage.removeItem(LocalStorageEnum.IS_CHECKOUT_PAGE);

  inputData.holidayKey = checkAvailabilityResponse?.data?.holidayKey;
  inputData.metaInfo = checkAvailabilityResponse?.data?.metaInfo;
  inputData.priceDetailInfo =
    checkAvailabilityResponse?.data?.holidayDetailInfo?.priceDetailInfo;
  inputData.contentfulCheckAvailability = contentfulCheckAvailability;
  inputData.showCustomModal = showCustomModal;
  inputData.hideCustomMobile = hideCustomMobile;

  checkScenario(showCustomModal, dispatch);
};

const checkScenario = (showCustomModal, dispatch) => {
  if (inputData.metaInfo?.holidayUnavailable) {
    scenarioNotAvailable(showCustomModal);
  } else if (inputData.metaInfo?.holidayPriceChanged) {
    scenarioPriceChange(showCustomModal, dispatch);
  } else {
    navigateToCheckOut(dispatch);
  }
};

const scenarioAvailable = (showCustomModal, dispatch) => {
  showCustomModal(
    false,
    setCheckAvailabilityModalContent(
      inputData.contentfulCheckAvailability.scenarios.available.header,
      inputData.contentfulCheckAvailability.scenarios.available.info,
      false,
      inputData.contentfulCheckAvailability.buttons.continue,
      false,
      null,
      null,
      null,
      null,
      () => navigateToCheckOut(dispatch)
    ),
    false,
    false
  );
};

const scenarioPriceChange = (showCustomModal, dispatch) => {
  showCustomModal(
    false,
    setCheckAvailabilityModalContent(
      inputData.contentfulCheckAvailability.scenarios.priceChange.header,
      inputData.contentfulCheckAvailability.scenarios.priceChange.info,
      inputData.contentfulCheckAvailability.buttons.return,
      inputData.contentfulCheckAvailability.buttons.continue,
      true,
      inputData.contentfulCheckAvailability.scenarios.priceChange
        .previousTotalPrice,
      inputData.priceDetailInfo.oldTotal,
      inputData.contentfulCheckAvailability.scenarios.priceChange.newTotalPrice,
      inputData.priceDetailInfo.total,
      () => navigateToCheckOut(dispatch)
    ),
    false,
    false
  );
};

const scenarioNotAvailable = (showCustomModal) => {
  showCustomModal(
    false,
    setCheckAvailabilityModalContent(
      inputData.contentfulCheckAvailability.scenarios.notAvailable.header,
      inputData.contentfulCheckAvailability.scenarios.notAvailable.info,
      inputData.contentfulCheckAvailability.buttons.return,
      false,
      false
    ),
    false,
    false
  );
};

const navigateToCheckOut = async (dispatch) => {
  if (typeof dispatch !== "function") {
    return;
}

  try {
    const checkOutDetailsResponse = await dispatch(
      getCheckOutDetailsCart(inputData.holidayKey)
    );
    const checkOutData = checkOutDetailsResponse.payload;

    const previousState =
      JSON.parse(localStorage.getItem(LocalStorageEnum.IS_CHECKOUT_PAGE)) ??
      false;

    const isCheckout =
      checkOutData?.additionalProductInfo?.ancillaryServiceInfo
        ?.showBaggageComponent === false &&
      checkOutData?.additionalProductInfo?.rentalCarInfo?.rentalCarAvailable === false;

    if (previousState !== isCheckout) {
      localStorage.setItem(
        LocalStorageEnum.IS_CHECKOUT_PAGE,
        JSON.stringify(!!isCheckout)
      );
    }

    if (isCheckout) {
      window.location.href = `${window.location.origin}${PagesConstant.BASEPATH}/${PagesConstant.CHECKOUT}`;
    } else {
      window.location.href = `${window.location.origin}${PagesConstant.BASEPATH}/${PagesConstant.EXTRAS}`;
    }
  } catch (error) {
    window.location.href = `${window.location.origin}${PagesConstant.BASEPATH}/${PagesConstant.EXTRAS}`;
  }
};

export const checkAvailabilityHelper = {
  processCheckAvailabilityResponse,
};
