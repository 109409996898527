export const PassengerDetailsFormFields = {
    GENDER: 'gender',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    DAY_OF_BIRTH: 'day',
    MONTH_OF_BIRTH: 'month',
    YEAR_OF_BIRTH: 'year',
    DATE_OF_BIRTH: 'dateOfBirth',
    FREQUENT_FLYER: 'frequentFlyer',
    FREQUENT_FLYER_AIRLINE_INFO: 'airlineInfo',
    FREQUENT_FLYER_CARD_NUMBER: 'loyaltyNumber',
    EMAIL: 'email',
    PHONE_COUNTRY_CODE: 'phoneNumberCountryCode',
    PHONE_NUMBER: 'phoneNumberSuffix',
    NINE_DIGIT_PHONE_NUMBER: 9,
    TEN_DIGIT_PHONE_NUMBER: 10,
    STREET: 'street',
    HOUSE_NUMBER: 'houseNumber',
    HOUSE_NUMBER_EXTENSION: 'houseNumberExtension',
    ZIP_CODE: 'zipCode',
    CITY: 'city',
    COUNTRY_DATA: 'countryData',
    COUNTRY_CODE: 'countryCode',
    COUNTRY_NAME: 'countryName',
    COMPANY_NAME: 'companyName',
    BLUE_BIZ_ACCOUNT: 'blueBizAccount',
    ADULT: 'Adult',
    CHILD: 'Child',
    INFANT: 'Infant',
    INVALID_DATE: 'Invalid date',
    DAY: 'day',
    MONTH: 'month',
    YEAR: 'year',
    MINIMUM_ADULT_AGE_RESPONSE: 11,
    DEFAULT_ADULT_AGE: "18",
    DEFAULT_ADULT_AGE_US: "21",
    DEFAULT_TRAVELLER_TYPE: "",
    DEFAULT_HEADER_TEXT: "Details main booker | Traveler 1 | Adult 18+ years"
}

export const HomeStayerFormName = 'homeStayer'
