import "./hotel-ribbon.component.scss";
import { useEffect, useState } from "react";
import { formatContentfulImageUrl } from "../../../services/contentful.service";
import { MarketingRibbonTypesEnum } from "../../../common/enums/marketingRibbonTypesEnum";

export const HotelRibbon = ({ contentfulData, marketingRibbonTag }) => {
  const [marketingRibbonUrl, setMarketingRibbonUrl] = useState("");

  useEffect(() => {
    if (contentfulData) {
      let ribbonType;
      if (marketingRibbonTag === MarketingRibbonTypesEnum.Recommended) {
        ribbonType = contentfulData[0].fields?.ribbonRecommended;
      } else if (marketingRibbonTag === MarketingRibbonTypesEnum.Bestseller) {
        ribbonType = contentfulData[0].fields?.ribbonBestseller;
      }
      setMarketingRibbonUrl(
        formatContentfulImageUrl(ribbonType?.fields?.file?.url)
      );
    }
  }, [contentfulData]);

  return (
    marketingRibbonUrl && (
      <div className="hotel-flipper-ribbon">
        <img src={marketingRibbonUrl} alt="" className="hotel-flipper-ribbon" />
      </div>
    )
  );
};
