import { useEffect, useState } from "react";
import { Accordion } from "../../accordion/accordion";
import { TripadvisorRatingComponent } from "../../tripadvisor-rating/tripadvisor-rating.component";
import { filterType } from "../../../common/constants/filterType";
import "./tripadvisor-rating-filter.component.scss";

export const TripadvisorRatingFilterComponent = ({ contentfulFilters, data, setGenericFilter }) => {
    const [tripAdvisorRatingFilterOptions, setStateTripAdvisorRatingFilterOptions] = useState([]);

    useEffect(() => {
        setStateTripAdvisorRatingFilterOptions(data);
    }, [data, tripAdvisorRatingFilterOptions]);

    const handleOnChange = (index) => {
        const output = tripAdvisorRatingFilterOptions.map((item, i) =>
            index === i
                ? item.selected = true
                : item.selected = false
        );
        setStateTripAdvisorRatingFilterOptions(output);
    };

    return (
        <div className="tripadvisor-rating-filter-component-container">
            <Accordion
                startingExpandedState="false"
                labelText={
                    contentfulFilters
                        ? contentfulFilters[0]?.fields?.reviews
                        : "Reviews"
                }
            >
                <div
                    className="filter-options-container"
                >
                    {
                        data?.map((element, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`filter-option ${ element.count === 0 && "tripadvisor-disabled"}`}
                                    onClick={() => [handleOnChange(index), setGenericFilter([{ minTripAdvisorRating: element.code }])]}
                                >
                                    <div className={
                                        tripAdvisorRatingFilterOptions && tripAdvisorRatingFilterOptions[index]?.selected
                                            ? "checkbox-container active"
                                            : "checkbox-container inactive"
                                    }>
                                        <div
                                            className="checkbox"
                                        ></div>
                                    </div>

                                    <div className="tripadvisor-rating-container">
                                        <TripadvisorRatingComponent
                                            data={parseInt(element.code)}
                                        />
                                    </div>

                                    <div className="text-container">
                                        {
                                            element.code !== filterType.excellent
                                                ? <>
                                                    {
                                                        contentfulFilters
                                                            ? contentfulFilters[0]?.fields?.orMore
                                                            : "or more"
                                                    }
                                                </>
                                                : null
                                        }
                                    </div>

                                    <div className="counter-container">
                                        {element.count}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Accordion>
        </div>
    );
};