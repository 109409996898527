import "@adyen/adyen-web/dist/adyen.css";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { updateCheckOutDataReducer } from "../../store/shopping/slice";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as BackIconKLM } from "../../assets/icons/klm/back_icon_KLM.svg";
import { ReactComponent as BackIconTransavia } from "../../assets/icons/transavia/back_icon_transavia.svg";
import { breakpoints } from "../../common/constants/breakpoints";
import { contentType } from "../../common/constants/contentType";
import { PagesConstant } from "../../common/constants/pages";
import { affiliates } from "../../common/enums/affiliates";
import { apiStatusCodeEnum } from "../../common/enums/apiStatusCodeEnum";
import { contentfulEntriesReducer } from "../../common/enums/contentfulEntriesReducer";
import { PaymentOptions } from "../../common/enums/paymentOptions";
import { paymentTypes } from "../../common/enums/paymentTypes";
import { reinitializePaymentStatus } from "../../common/enums/reinitializePaymentStatus";
import CheckoutCard from "../../components/checkout-card/checkout-card";
import { DownPaymentComponent } from "../../components/downpayment/downpayment.component";
import FlightsAndArrivals from "../../components/flights-and-arrivals/flights-and-arrivals";
import { InsuranceComponent } from "../../components/insurance/insurance.component";
import { PagesStepperBarComponent } from "../../components/pages-stepper-bar/pages-stepper-bar.component";
import { PaymentHotelSummaryOverviewComponent } from "../../components/payment-hotel-summary-overview/payment-hotel-summary-overview.component";
import { ShoppingCartComponent } from "../../components/shopping-cart/shopping-cart.component";
import TermsAndConditions from "../../components/terms-and-conditions/terms-and-conditions";
import { VoucherCodeComponent } from "../../components/voucher-code/voucher-code.component";
import { waitingModalMessageTypes } from "../../components/waiting-modal/waiting-modal-message-service";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import {
  FinalisePayment,
  MakePayment,
} from "../../services/adyen/adyen.service";
import { getContentfulByContentType } from "../../services/contentful.service";
import { GetAffiliate } from "../../services/general.service";
import ContentfulContext from "../../store/contentful/contentful-context";
import { getCheckOutDetailsCart } from "../../store/shopping/slice";
import PaymentFormComponent from "./payment-form/payment-form-component";
import { LocalStorageEnum } from "../../common/enums/localStorageEnum";
import "./payment-page.component.scss";
import { AnalyticsService } from "../../services/analytics/analytics.service";

const initialState = {
  contentfulButtons: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case contentfulEntriesReducer.PAYMENT_PAGE:
      return { ...state, contentfulPaymentPage: action.payload };
    case contentfulEntriesReducer.HOLIDAY_PAGE:
      return { ...state, contentfulHolidayPage: action.payload };
    case contentfulEntriesReducer.ROOM_ALLOCATION:
      return { ...state, contentfulRoomAllocation: action.payload };
    case contentfulEntriesReducer.BUTTONS:
      return { ...state, contentfulButtons: action.payload };
    case contentfulEntriesReducer.ADYEN_COMPONENT:
      return { ...state, contentfulAdyenComponent: action.payload };
    default:
      throw new Error("Unknown data");
  }
};

export const PaymentPageComponent = ({
  assignCheckOutData,
  showWaitingScreen,
  checkOutData,
  hideWaitingScreen,
  queryParameters,
  setDeepLinkParams,
}) => {
  const dispatch = useDispatch();
  const contentfulEntries = useContext(ContentfulContext);
  const [
    {
      contentfulPaymentPage,
      contentfulHolidayPage,
      contentfulRoomAllocation,
      contentfulButtons,
      contentfulAdyenComponent,
    },
    dispatchContentful,
  ] = useReducer(reducer, initialState);
  const windowSizeDetector = useWindowSizeDetector();
  const [isMobile, setIsMobile] = useState(false);
  const [finalisedPaymentData, setFinalisedPaymentData] = useState(false);
  const [paymentResults, setPaymentResults] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const checkoutData = useSelector((state) => state.checkOut.checkOutData);
  const holidayKey = useSelector((state) => state.holidayData.holidayKey);

  const [isTermsAndConditionsCheck, setTermsAndConditionsCheck] =
    useState(false);
  const travellersData = checkoutData?.travellerInfo?.travellers || [];
  const mainbookerData = checkoutData || [];
  const homestayerData = checkoutData?.contactDetailInfo?.homeStayer || [];
  const [reinitializePayment, setReinitializePayment] = useState(
    reinitializePaymentStatus.INITIAL
  );
  const flightData =
    checkoutData?.checkOutSummary?.flightDetails?.outboundFlight || [];
  const arrivalData =
    checkoutData?.checkOutSummary?.flightDetails?.inboundFlight || [];
  const [expandedType, setExpandedType] = useState(null);

  const [idealPayment, setIdealPayment] = useState(null);
  const [creditCardPayment, setCreditCardPayment] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [analyticsActionTrigger, setAnalyticsActionTrigger] = useState(false);
  const [downPaymentVisibilityTrigger, setStateDownPaymentVisibilityTrigger] =
    useState(true);
  const [isVoucherApplied, setIsVoucherApplied] = useState(false);

  const toggleExpand = (newType) => {
    setExpandedType((prevType) => {
      const newExpandedType = prevType === newType ? null : newType;
      return newExpandedType;
    });
  };

  const contentfulPassengerDetailsPageResponse = () => {
    const contentfulPaymentPage = getContentfulByContentType(
      contentfulEntries,
      contentType.PAYMENT_PAGE
    );
    const contentfulHolidayPage = getContentfulByContentType(
      contentfulEntries,
      contentType.HOLIDAY_PAGE
    );
    const contentfulRoomAllocation = getContentfulByContentType(
      contentfulEntries,
      contentType.ROOM_ALLOCATION
    );
    const contentfulButtons = getContentfulByContentType(
      contentfulEntries,
      contentType.BUTTONS
    );
    const contentfulAdyenComponent = getContentfulByContentType(
      contentfulEntries,
      contentType.ADYEN_COMPONENT
    );

    dispatchContentful({
      type: contentfulEntriesReducer.PAYMENT_PAGE,
      payload: contentfulPaymentPage,
    });
    dispatchContentful({
      type: contentfulEntriesReducer.HOLIDAY_PAGE,
      payload: contentfulHolidayPage,
    });
    dispatchContentful({
      type: contentfulEntriesReducer.ROOM_ALLOCATION,
      payload: contentfulRoomAllocation,
    });

    dispatchContentful({
      type: contentfulEntriesReducer.BUTTONS,
      payload: contentfulButtons,
    });
    dispatchContentful({
      type: contentfulEntriesReducer.ADYEN_COMPONENT,
      payload: contentfulAdyenComponent,
    });
  };

  useEffect(() => {
    if (checkoutData) {
      assignCheckOutData(checkoutData);
      setDeepLinkParams(checkoutData?.metaInfo?.navigateToCheckOut);

      if (!analyticsActionTrigger) {
        AnalyticsService.handleEvents([
          {
            platform: AnalyticsService.analyticsConstants.platform.gtmRelay42,
            type: AnalyticsService.analyticsConstants.eventType.pageView,
            name: AnalyticsService.analyticsConstants.apiEvents.payment,
            data: checkoutData.checkOutKey,
          },
          {
            platform: AnalyticsService.analyticsConstants.platform.gtag,
            type: AnalyticsService.analyticsConstants.eventType.pageView,
            data: AnalyticsService.analyticsConstants.apiEvents.payment,
          },
        ]);
        setAnalyticsActionTrigger(true);
      }

      setStateDownPaymentVisibilityTrigger(
        displayTrigger(checkoutData?.paymentInfo?.downPaymentAmount)
      );
    }
  }, [checkoutData]);

  useEffect(() => {
    const isMobileSize =
      windowSizeDetector.width > breakpoints.XXS &&
      windowSizeDetector.width < breakpoints.SM;

    setIsMobile(isMobileSize);
  }, [windowSizeDetector.width]);

  useEffect(() => {
    contentfulPassengerDetailsPageResponse();
  }, [contentfulEntries]);

  useEffect(() => {
    const loadData = async () => {
      try {
        await dispatch(getCheckOutDetailsCart(holidayKey));
        setIsDataLoaded(true);
      } catch (error) {
        console.error(error);
      }
    };
    loadData();
  }, [dispatch, holidayKey]);

  const finalisePayment = async () => {
    showWaitingScreen(waitingModalMessageTypes.processingPayment);
    try {
      const paymentResults = await FinalisePayment(
        queryParameters.redirectResult,
        holidayKey
      );
      setFinalisedPaymentData(paymentResults);
      hideWaitingScreen();
      setPaymentResults(paymentResults);
      if (paymentResults.data?.paymentDetails?.paymentCancelled) {
        setReinitializePayment(reinitializePaymentStatus.NOT_CONFIRMED);
      }
      if (paymentResults.data?.paymentDetails?.paymentConfirmed) {
        setReinitializePayment(reinitializePaymentStatus.CONFIRMED);
        window.location.href = `${PagesConstant.BASEPATH}/${PagesConstant.CONFIRMATION}`;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePayPressed = async () => {
    showWaitingScreen(waitingModalMessageTypes.processingPayment);
    const paymentResult = await MakePayment(checkoutData.checkOutKey);
    hideWaitingScreen();

    if (paymentResult.status === apiStatusCodeEnum.success) {
      if (paymentResult.data.paymentDetails?.redirectRequired) {
        if (
          paymentResult.data.paymentDetails?.action.paymentMethodType ===
            PaymentOptions.CARD ||
          paymentResult.data.paymentDetails.action.paymentMethodType ===
            PaymentOptions.SCHEME
        ) {
          creditCardPayment.handleAction(
            paymentResult.data.paymentDetails?.action
          );
        } else {
          idealPayment.handleAction(paymentResult.data.paymentDetails?.action);
        }
      } else {
        window.location.href = `${PagesConstant.BASEPATH}/${PagesConstant.CONFIRMATION}/`;
      }
    }
  };

  const handleRedirectBack = () => {
    window.location.href = checkoutData.metaInfo.navigateToCheckOut;
  };

  const allowProceedPayment = () => {
    const insuranceSelectionCompleted =
      checkOutData.additionalProductInfo?.insuranceInfo
        ?.insuranceSelectionCompleted;
    const paymentMethodSelected = checkOutData.paymentInfo?.paymentSelected;
    const paymentValidationIdeal = idealPayment !== null;
    const paymentValidationCard = creditCardPayment?.state?.isValid;

    return (
      paymentMethodSelected &&
      insuranceSelectionCompleted &&
      isTermsAndConditionsCheck &&
      (paymentValidationIdeal || paymentValidationCard)
    );
  };

  const showDownPaymentComponent = (param) => {
    setStateDownPaymentVisibilityTrigger(param);
  };

  const displayTrigger = (value) => {
    const output =
      !isNaN(parseInt(value)) && parseInt(value) !== 0 ? true : false;

    return output;
  };

  return (
    <div className="payment-page-component">
      <PagesStepperBarComponent />

      <div className="payment-page-container">
        <div className="left-side">
          <InsuranceComponent
            contentfulPaymentPage={contentfulPaymentPage}
            contentfulHolidayPage={contentfulHolidayPage}
            insuranceInfoDetails={
              checkoutData?.additionalProductInfo?.insuranceInfo
            }
          />
          <PaymentHotelSummaryOverviewComponent
            contentfulPaymentPage={contentfulPaymentPage}
            contentfulRoomAllocation={contentfulRoomAllocation}
            hotelInfo={checkoutData?.shoppingCart?.hotelInfo}
            checkOutSummary={checkoutData?.checkOutSummary}
          />

          <div className="checkout-component">
            <div className="flights-arrivals-container">
              <FlightsAndArrivals
                type={paymentTypes.FLIGHTS}
                data={flightData}
                isMobile={isMobile}
                title={
                  contentfulPaymentPage &&
                  contentfulPaymentPage[0]?.fields?.departureFlight
                }
                contentfulPaymentPage={contentfulPaymentPage}
                contentfulHolidayPage={contentfulHolidayPage}
                expandedType={expandedType}
                toggleExpand={toggleExpand}
              />
              <FlightsAndArrivals
                type={paymentTypes.ARRIVALS}
                data={arrivalData}
                isMobile={isMobile}
                title={
                  contentfulPaymentPage &&
                  contentfulPaymentPage[0]?.fields?.arrivalFlight
                }
                contentfulPaymentPage={contentfulPaymentPage}
                contentfulHolidayPage={contentfulHolidayPage}
                expandedType={expandedType}
                toggleExpand={toggleExpand}
              />
            </div>

            <CheckoutCard
              type={paymentTypes.TRAVELLER}
              typeTraveller={paymentTypes.TRAVELLER}
              typeHomeStayer={paymentTypes.HOMESTAYER}
              typeMainBooker={paymentTypes.MAINBOOKER}
              dataTraveller={travellersData}
              dataHomeStayer={homestayerData}
              dataMainBooker={mainbookerData}
              checkFlightOption={flightData}
              titleTraveller={
                contentfulPaymentPage &&
                contentfulPaymentPage[0]?.fields?.travelerData
              }
              titleTravellerNotDriver={
                contentfulHolidayPage &&
                contentfulHolidayPage[0]?.fields?.travellerDataNotDriver
              }
              titleHomeStayer={
                contentfulPaymentPage &&
                contentfulPaymentPage[0]?.fields?.stayingHome
              }
              titleMainBooker={
                contentfulPaymentPage &&
                contentfulPaymentPage[0]?.fields?.contactInformation
              }
              contentfulPaymentPage={contentfulPaymentPage}
              contentfulHolidayPage={contentfulHolidayPage}
              travellerBaggageInfo={
                checkoutData?.additionalProductInfo?.ancillaryServiceInfo
              }
            />
          </div>
          <VoucherCodeComponent
            contentfulPaymentPage={contentfulPaymentPage}
            contentfulButtons={contentfulButtons}
            checkOutSummary={checkoutData?.checkOutSummary}
            checkoutData={checkOutData}
            assignCheckOutData={assignCheckOutData}
            showDownPaymentComponent={showDownPaymentComponent}
            isVoucherApplied={isVoucherApplied}
            setIsVoucherApplied={setIsVoucherApplied}
          />

          <>
            {downPaymentVisibilityTrigger && (
              <DownPaymentComponent
                contentfulPaymentPage={contentfulPaymentPage}
                checkoutData={checkoutData}
              />
            )}
          </>

          <PaymentFormComponent
            contentfulAdyenComponent={contentfulAdyenComponent}
            setCreditCardPayment={setCreditCardPayment}
            setIdealPayment={setIdealPayment}
            contentfulButtons={contentfulButtons}
            checkOutData={checkoutData}
            showWaitingScreen={showWaitingScreen}
            hideWaitingScreen={hideWaitingScreen}
            queryParameters={queryParameters}
            finalisePayment={finalisePayment}
            paymentResults={paymentResults}
            reinitializePayment={reinitializePayment}
            setReinitializePayment={setReinitializePayment}
            finalisedPaymentData={finalisedPaymentData}
            isTermsAndConditionsCheck={isTermsAndConditionsCheck}
            setPaymentMethod={setPaymentMethod}
            isVoucherApplied={isVoucherApplied}
          />
          <TermsAndConditions
            contentfulPaymentPage={contentfulPaymentPage}
            setTermsAndConditionsCheck={setTermsAndConditionsCheck}
          />
          <div className="buttons-container">
            <button className="back-button" onClick={handleRedirectBack}>
              {GetAffiliate() === affiliates.klm ? (
                <BackIconKLM />
              ) : GetAffiliate() === affiliates.transavia ? (
                <BackIconTransavia />
              ) : null}
              {contentfulButtons && contentfulButtons[0]?.fields?.back}
            </button>
            <button
              className={`make-payment-button`}
              disabled={!allowProceedPayment()}
              onClick={handlePayPressed}
            >
              {contentfulButtons && contentfulButtons[0]?.fields?.paymentButton}
            </button>
          </div>
        </div>
        <div className="shopping-cart-container">
          <ShoppingCartComponent checkOutData={checkoutData} />
        </div>
      </div>
    </div>
  );
};
