import "./transfers.component.scss";

import { Alert } from "@mui/material";
import { TransferCardComponent } from "./transfers-card/transfers-card.component";
import { useState } from "react";

export const TransferComponent = ({
  contentfulTransfer,
  contentfulButtons,
  transfersInfo,
  holidayKey,
  onSelectTransfer,
  isCarSelected,
  isWaitingForNetwork,
}) => {
  const [activeCard, setActiveCard] = useState(null);

  return (
    transfersInfo?.transferOptions && (
      <div className="transfers-component">
        <h1 className="section-heading-text">
          {contentfulTransfer?.transferTitle}
        </h1>
        {isCarSelected && (
          <div className="option-no-available">
            <Alert className="transfer-alert" severity="info">
              {contentfulTransfer &&
                contentfulTransfer?.transferNoSelectionAlert}
            </Alert>
          </div>
        )}
        <div className={isCarSelected && "transfer-component__opacity"}>
          <div className="transfer-description">
            {contentfulTransfer?.transferDescription}
          </div>
          <div className="transfers-card-container">
            {transfersInfo?.transferOptions?.map((option, index) => (
              <TransferCardComponent
                key={index}
                holidayKey={holidayKey}
                isCardSelected={false}
                transferOption={option}
                contentfulTransfer={contentfulTransfer}
                contentfulButtons={contentfulButtons}
                onSelectTransfer={onSelectTransfer}
                isWaitingForNetwork={isWaitingForNetwork}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
            ))}
          </div>
        </div>
      </div>
    )
  );
};
