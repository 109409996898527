import "./filters.component.scss";

import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";
import { action } from "../../common/constants/action";
import { breakpoints } from "../../common/constants/breakpoints";
import { affiliates } from "../../common/enums/affiliates";
import { LocalStorageEnum } from "../../common/enums/localStorageEnum";
import { searchParamsEnum } from "../../common/enums/searchParamsEnum";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import { GetAffiliate } from "../../services/general.service";
import { LocalStorageService } from "../../services/local-storage.service";
import { AllInclusiveComponent } from "./all-inclusive-filter/all-inclusive-filter.component";
import { AmenitiesFilterComponent } from "./amenities-filter/amenities-filter.component";
import { DestinationFilterComponent } from "./destination-filter/destination-filter.component";
import { DistanceToCenterFilterComponent } from "./distance-to-center-filter/distance-to-center-filter.component";
import { methods } from "./filters.methods";
import { HotelNameFilterComponent } from "./hotel-name-filter/hotel-name-filter.component";
import { PriceFilterComponent } from "./price-filter/price-filter.component";
import { SelectedFilterComponent } from "./selected-filters/selected-filters.component";
import { StarRatingFilterComponent } from "./star-rating-filter/star-rating-filter.component";
import { ThemeFilterComponent } from "./theme-filter/theme-filter.component";
import { TripadvisorRatingFilterComponent } from "./tripadvisor-rating-filter/tripadvisor-rating-filter.component";

export const FiltersComponent = ({
  contentfulButtons,
  contentfulResultsPageResponse,
  holidayFullData,
  performSearch,
  performFilter,
  contentfulFilters,
  handleOnClose,
  defaultSearchUsed,
  setCountriesLocations,
  setCitiesLocations,
  setDestinationFilters,
  destinationModalFilter,
  setApiData,
  setGenericFilterModal,
  setIsSearchPerformed,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [cloneOfHolidayFullData, setStateCloneOfHolidayFullData] =
    useState(null);
  const [genericFilter, setStateGenericFilter] = useState(null);
  const [destinationFilter, setStateDestinationFilter] = useState(null);
  const windowSizeDetector = useWindowSizeDetector();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    windowSizeDetector.width < breakpoints.SM
      ? setIsMobile(true)
      : setIsMobile(false);

    windowSizeDetector.width > breakpoints.SM &&
    windowSizeDetector.width < breakpoints.XL
      ? setIsTablet(true)
      : setIsTablet(false);
  });

  useEffect(() => {
    setStateCloneOfHolidayFullData(
      holidayFullData ? JSON.parse(JSON.stringify(holidayFullData)) : null
    );
    if (setApiData) {
      setApiData(
        holidayFullData ? JSON.parse(JSON.stringify(holidayFullData)) : null
      );
      setGenericFilterModal({
        ...holidayFullData?.data?.filterInfo?.requestedFilters,
      });
    }
    setStateGenericFilter({
      ...holidayFullData?.data?.filterInfo?.requestedFilters,
    });
    setStateDestinationFilter(
      holidayFullData?.data?.searchInfo?.destinationLocationCode
    );
  }, [holidayFullData]);

  useEffect(() => {
    if (destinationFilter != null) {
      if (
        destinationFilter !==
        holidayFullData?.data?.searchInfo?.destinationLocationCode
      ) {
        performSearch(
          methods.setSearchRequestBody(
            cloneOfHolidayFullData,
            genericFilter,
            destinationFilter
          )
        );
      }

      setSearchParams((params) => {
        params.set(searchParamsEnum.destinationLocation, destinationFilter);
        return params;
      });
      if (GetAffiliate() === affiliates.transavia)
        setSearchParams((params) => {
          params.set(
            searchParamsEnum.destinationAirportCode,
            cloneOfHolidayFullData.data.searchInfo.destinationLocationCode
          );
          return params;
        });
    }
  }, [destinationFilter, destinationModalFilter]);

  const onMobileButtonClickAction = (requestedAction) => {
    if (requestedAction === action.apply) {
      setIsSearchPerformed(true);
      performSearch(
        methods.setSearchRequestBody(
          cloneOfHolidayFullData,
          genericFilter,
          destinationFilter
        )
      );
    } else if (requestedAction === action.cancel) {
      setStateCloneOfHolidayFullData(holidayFullData);
      if (setApiData) {
        setApiData(holidayFullData);
      }
      setSearchParams((params) => {
        params.set(
          searchParamsEnum.destinationLocation,
          cloneOfHolidayFullData.data.searchInfo.destinationLocationCode
        );
        return params;
      });
      if (GetAffiliate() === affiliates.transavia)
        setSearchParams((params) => {
          params.set(
            searchParamsEnum.destinationAirportCode,
            cloneOfHolidayFullData.data.searchInfo.destinationLocationCode
          );
          return params;
        });
      LocalStorageService.setLocalStorageItem(
        LocalStorageEnum.DESTINATION_LOCATIONS,
        cloneOfHolidayFullData.data.searchInfo.destinationLocationCode
      );
    } else {
      console.error(action.unknown);
    }
  };

  const setGenericFilter = (filter) => {
    const filterContent = methods.setGenericFilterContent(
      genericFilter,
      filter
    );
    setStateGenericFilter(filterContent);
    if (!isMobile && !isTablet) {
      performFilter(
        methods.setAddFilterRequestBody(
          cloneOfHolidayFullData?.data?.holidayListKey,
          destinationFilter,
          filterContent
        )
      );
    }
  };

  const setDestinationFilter = (filter) => {
    const searchInfo = { ...cloneOfHolidayFullData.data.searchInfo };
    const destinationFilterContent = methods.setFilterDestinationLocations(
      searchInfo,
      filter
    );
    setStateDestinationFilter(destinationFilterContent);
  };

  const content = (
    <>
      <div className="filters-component">
        <div className="filters-component-elements">
          {isMobile || isTablet ? (
            <div
              id="filters-button-container"
              className="filters-button-container"
            >
              <div
                className="button"
                onClick={() => [
                  onMobileButtonClickAction(action.apply),
                  handleOnClose(),
                ]}
              >
                {contentfulButtons
                  ? contentfulButtons[0]?.fields?.applyFilters
                  : "Apply filters"}
              </div>
            </div>
          ) : null}

          <div className="filters-header">
            {contentfulFilters
              ? contentfulFilters[0]?.fields?.filters
              : "Filters"}
          </div>

          <div className="filters-content">
            <SelectedFilterComponent
              holidayFullData={cloneOfHolidayFullData}
              contentfulButtons={contentfulButtons}
              contentfulResultsPageResponse={contentfulResultsPageResponse}
              contentfulFilters={contentfulFilters}
              performSearch={performSearch}
              performFilter={performFilter}
            />
            <DestinationFilterComponent
              contentfulButtons={contentfulButtons}
              contentfulFilters={contentfulFilters}
              holidayFullData={cloneOfHolidayFullData}
              data={cloneOfHolidayFullData?.data?.filterInfo?.filterOptions}
              setDestinationFilter={setDestinationFilter}
              defaultSearchUsed={defaultSearchUsed}
              setCountriesLocations={setCountriesLocations}
              setCitiesLocations={setCitiesLocations}
              setDestinationFilters={setDestinationFilters}
              performSearch={performSearch}
              performFilter={performFilter}
              setIsSearchPerformed={setIsSearchPerformed}
              cloneOfHolidayFullData={cloneOfHolidayFullData}
              genericFilter={genericFilter}
              destinationFilter={destinationFilter}
              isMobile={isMobile}
            />
            {!defaultSearchUsed && (
              <>
                <HotelNameFilterComponent
                  contentfulFilters={contentfulFilters}
                  data={cloneOfHolidayFullData?.data}
                  setGenericFilter={setGenericFilter}
                  isMobile={isMobile}
                />
                <PriceFilterComponent
                  contentfulFilters={contentfulFilters}
                  data={cloneOfHolidayFullData?.data}
                  setGenericFilter={setGenericFilter}
                />
                <DistanceToCenterFilterComponent
                  contentfulFilters={contentfulFilters}
                  data={cloneOfHolidayFullData?.data}
                  setGenericFilter={setGenericFilter}
                />
                <StarRatingFilterComponent
                  contentfulFilters={contentfulFilters}
                  data={
                    cloneOfHolidayFullData?.data?.filterInfo?.filterOptions
                      ?.starsFilterOptions
                  }
                  setGenericFilter={setGenericFilter}
                />
                <TripadvisorRatingFilterComponent
                  contentfulFilters={contentfulFilters}
                  data={
                    cloneOfHolidayFullData?.data?.filterInfo?.filterOptions
                      ?.tripAdvisorRatingFilterOptions
                  }
                  setGenericFilter={setGenericFilter}
                />
                <AllInclusiveComponent
                  contentfulFilters={contentfulFilters}
                  data={cloneOfHolidayFullData?.data?.filterInfo}
                  setGenericFilter={setGenericFilter}
                  performFilter={performFilter}
                />
                <AmenitiesFilterComponent
                  contentfulFilters={contentfulFilters}
                  data={cloneOfHolidayFullData?.data?.filterInfo}
                  setGenericFilter={setGenericFilter}
                />
                <ThemeFilterComponent
                  contentfulFilters={contentfulFilters}
                  data={cloneOfHolidayFullData?.data?.filterInfo}
                  setGenericFilter={setGenericFilter}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );

  return <div className="filters-component-container">{content}</div>;
};
