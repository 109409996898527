import Carousel from "react-material-ui-carousel";
import "./hotel-image-flipper.scss";
import { GetAffiliate } from "../../../services/general.service";
import { affiliateStyleRules } from "../../../common/affiliate-style-rules";
import { HotelRibbon } from "../hotel-ribbon/hotel-ribbon.component";

export const HotelImageFlipper = ({
  hotelImages,
  marketingRibbonTag,
  contentfulData,
}) => {
  const affiliateSpecificArrowColour =
    affiliateStyleRules[GetAffiliate()].imageFlipperArrowColour;

  const HotelFlipperItem = ({ hotelImage }) => {
    return (
      <div className="hotel-flipper-image-parent">
        <img
          src={hotelImage}
          className="holiday-card-preview-image"
          alt="hotel"
          style={{ objectFit: "cover", height: "100%", width: "100%" }}
        />
      </div>
    );
  };

  return (
    <div className="hotel-flipper-image-parent">
      <HotelRibbon
        marketingRibbonTag={marketingRibbonTag}
        contentfulData={contentfulData}
      />
      <Carousel
        indicators={false}
        autoPlay={false}
        navButtonsProps={{
          style: {
            backgroundColor: "white",
            color: affiliateSpecificArrowColour,
            opacity: 1,
            width: "32px",
            height: "32px",
          },
        }}
      >
        {hotelImages.map((item, i) => (
          <HotelFlipperItem key={i} hotelImage={item} />
        ))}
      </Carousel>
    </div>
  );
};
